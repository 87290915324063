import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Table } from "react-bootstrap"
import { AddLocation } from "@material-ui/icons"
import { allowEmpty } from "../../../components/LoadingWrapper/LoadingWrapper"
import LargeCard from "../../../components/Structure/LargeCard"
import { LoadingWrapper } from "../../../components"
import { useLoading, useQueryLoading } from "../../../hooks/useLoading"
import useAPI from "../../../services/ApiService"
import useCameraManagementAPI from "../../../services/CameraManagementService"
import { Fragment } from "react"
import TableRowAction from "components/Table/TableRowAction"
import { useQueryClient } from "react-query"
import toast, { Toaster } from "react-hot-toast"
import { Delete } from "@material-ui/icons"

const MultiSceneDashboard = () => {
  const { user } = useSelector((state) => state.auth)

  // Load the existing cameras
  const dataAPI = useAPI()
  const confAPI = useCameraManagementAPI()
  const queryClient = useQueryClient()
  const [scenes, scenesLoadingState] = useQueryLoading("scenes", () => confAPI.createMultiScene())

  // Add new camera
  const headerOptions = (
    <div className="float-right">
      <Link to="/multiscene/add" className="btn btn-primary">
        <AddLocation /> Add a new scene
      </Link>
    </div>
  )
  // Delete a camera
  const deleteCamera = async (camera, location) => {
    if (window.confirm(`Are you sure you want to delete camera "${camera.name}" at "${location.locality}"?`)) {

      // Create camera and invalidate the list on success
      const promise = confAPI.deleteMultiScene(camera.uuid)
        .then(() =>{
           queryClient.invalidateQueries("scenes")
          toast.success("Multi-Scene deleted successfully")
          })

      // Show a friendly toaster
      await Toaster.promise(promise, "Multi-Scene deleted successfully")
    }
  }
  console.log(scenes)
  return (
    <LargeCard title="Multi-Scene" headerOptions={headerOptions}>
      <LoadingWrapper state={[scenesLoadingState]} onEmpty={"There is no multi-scene to show"}>
        {scenes?.length > 0 ? (
          <Table bordered hover={false} className="v-align">
            <thead>
              <tr>

                <th width="30%" />
                <th width="*">Camera name</th>
              </tr>
            </thead>
            <tbody>
              {scenes?.map((item, i) => <Fragment key={i}>
                <tr className="table-header">
                  <td colSpan="8">{item.location.country} - {item.location.locality} - {item.location.street}
                  </td>
                </tr>
                {item.cameras.map(cam => <Fragment key={cam.uuid}>
                  <tr>
                    <td width='30%'></td>
                    <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{cam.name} </span>
                      <TableRowAction
                        actions={[
                          { icon: <Delete />, title: "Delete camera", setState: () => deleteCamera(cam, item.location) },
                        ]}
                      />
                    </td>
                  </tr>
                </Fragment>)}
              </Fragment>)}

            </tbody>
          </Table>
        ) : (
          <h1 className="text-center my-10">No scenes added yet</h1>
        )}
      </LoadingWrapper>
    </LargeCard>
  )
}

export default MultiSceneDashboard
