import { useState } from "react"
import { Table } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { buildReportLink } from "../Reports"
import { useLoading } from "hooks/useLoading"
import { ExportToCsv, LoadingWrapper } from "components"
import LargeCard from "components/Structure/LargeCard"
import ReportFilters from "components/Reports/ReportFilters"
import { readReportParamsFromQuery } from "../Reports"
import { ArrowBack } from "@material-ui/icons"
import useAPI from "services/ApiService"
import useStoreDateRange from "hooks/useStoreDateRange"
import { humanDate, humanDayTime } from "utils/time"

export const PedestrianSummaryReport = () => {
    const dateRange = useStoreDateRange()
    const api = useAPI()

    // Read Report type
    const reportType = window.location.pathname.split("/")[2]

    // Read report parameters from the URL
    const [params, updateParams] = useState(readReportParamsFromQuery())
    const [report, reportLoadingState] = useLoading(() =>
        api.getPedestrianSummary(params.systemID, params.cameraID, dateRange),
        [params],
    )

    if (!report) {
        return null
    }

    // CSV table
    const csv = [[`${humanDate(report.start) - humanDate(report.end)}`, report.count]]
    csv?.unshift(["Start time", "End time", "Count"])

    // The header options
    const headerOptions = (
        <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
            <NavLink className="mr-5" to={buildReportLink(reportType, params, true)}>
                <ArrowBack /> Back to reports generator
            </NavLink>
            <ExportToCsv data={csv} filename="Pedestrian summary" />
        </div>
    )

    return (
        <LargeCard title="Pedestrian summary report" headerOptions={headerOptions}>

            <LoadingWrapper state={reportLoadingState} onEmpty={"There is no data to show for the supplied filter"}>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th width="300px">Start time</th>
                            <th width="300px">End time</th>
                            <th width="200px">Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {humanDate(report?.start)} {humanDayTime(report?.start)}
                            </td>
                            <td>{humanDate(report?.end)} {humanDayTime(report?.end, true)}</td>
                            <td>
                                {report?.count}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </LoadingWrapper>
        </LargeCard>
    )
}
