import { useState } from "react"
import { Table } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { buildReportLink } from "../Reports"
import { useLoading } from "hooks/useLoading"
import { ExportToCsv, LoadingWrapper } from "components"
import { humanNumber, humanPercent, humanSpeed, humanTotal } from "utils/functions"
import LargeCard from "components/Structure/LargeCard"
import ReportFilters from "components/Reports/ReportFilters"
import { csvAvgSpeed, csvPercent } from "utils/csv"
import { readReportParamsFromQuery } from "../Reports"
import { ArrowBack } from "@material-ui/icons"
import useAPI from "services/ApiService"
import useStoreDateRange from "hooks/useStoreDateRange"

export const VehicleTypeSummaryReportPage = () => {
  const dateRange = useStoreDateRange()
  const api = useAPI()

  // Read Report type
  const reportType = window.location.pathname.split("/")[2]

  // Read report parameters from the URL
  const [params, updateParams] = useState(readReportParamsFromQuery())

  // Load the data
  const [camera: CameraDTO] = useLoading(() => api.camera(params))
  const [report: VehicleTypeSummaryReportDTO, reportLoadingState] = useLoading(() =>
      api.getVehicleTypeSummary(params.systemID, params.cameraID, dateRange, params.carriageways, params.lanes, params.vehicles),
    [params],
    ["data"]
  )

  // Get the total
  const total = report?.data?.reduce((acc, item) => acc + item.count, 0)

  // CSV table
  const csv = report?.data.map((row) => [
    row.name,
    row.count,
    csvPercent(row.percentage),
    csvAvgSpeed(row.avg_speed),
    // csvAvgSpeed(row.p01_speed),
    csvAvgSpeed(row.p85_speed)
  ])
  csv?.unshift(["Type", "Count", "Percent [%]", "Avg speed [km/h]", "85th percentile [km/h]"])
  total && csv.push(["Total", total])

  // The header options
  const headerOptions = (
    <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
      <NavLink className="mr-5" to={buildReportLink(reportType, params, true)}>
        <ArrowBack /> Back to reports generator
      </NavLink>
      <ExportToCsv data={csv} filename="Vehicle type summary" />
    </div>
  )

  return (
    <LargeCard title="Vehicle type summary report" headerOptions={headerOptions}>

      {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

      <LoadingWrapper state={reportLoadingState} onEmpty={"There is no data to show for the supplied filter"}>
        <Table bordered hover>
          <thead>
            <tr>
              <th width="*">Type</th>
              <th width="15%" className="text-right">Count</th>
              <th width="15%" className="text-right">Percent %</th>
              <th width="25%" className="text-right">Avg speed</th>
              {/*<th width="15%" className="text-right">Min speed</th>*/}
              <th width="25%" className="text-right">85th percentile</th>
            </tr>
          </thead>
          <tbody>
            {report?.data?.map((row, index) => (
              <tr key={index}>
                <td>{row.name}</td>
                <td className="text-right">{humanNumber(row.count)}</td>
                <td className="text-right">{humanPercent(row.percentage)}</td>
                <td className="text-right">{humanSpeed(row.avg_speed)}</td>
                {/*<td className="text-right">{humanSpeed(row.p01_speed)}</td>*/}
                <td className="text-right">{humanSpeed(row.p85_speed)}</td>
              </tr>
            ))}
            <tr>
              <td className="text-right" colSpan="2">
                {humanTotal(humanNumber(report?.data?.reduce((acc, item) => acc + item.count, 0)))}
              </td>
              <td colSpan="3" />
            </tr>
          </tbody>
        </Table>
      </LoadingWrapper>
    </LargeCard>
  )
}
