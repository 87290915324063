import React, { useEffect, useRef, useState } from 'react';
import { defaultCameraImage, getCameraImageURL } from 'utils/camera';
import { get } from 'utils/data';
import { convertToHeatmapData } from 'utils/functions';
import Heatmap from 'visual-heatmap'; // You might need to adjust this import based on the actual package
import Image from 'img/no-image.png'

function HeatmapComp({ camera, image, imageUrl }) {
    const canvasRef = useRef(null);
    const instanceRef = useRef(null);

    useEffect(() => {
        let data = []
        if (!canvasRef.current) return;
        if (imageUrl) {
            instanceRef.current = new Heatmap(canvasRef.current, {
                max: 100,
                intensity: 1.0,
                zoom: 1.0,
                points: 10000,
                size: 15,
                opacity: 1.0,
                rotationAngle: 0.0,
                backgroundImage: {
                    url: Image,
                    x: 0,
                    y: 0
                },
                gradient: [
                    { color: [0, 0, 0, 0.0], offset: 0 },
                    { color: [0, 0, 255, 0.2], offset: 0.2 },
                    { color: [0, 255, 0, 0.5], offset: 0.45 },
                    { color: [255, 255, 0, 1.0], offset: 0.85 },
                    { color: [255, 0, 0, 1.0], offset: 1.0 }
                ]
            });
            data = generateData(10000);
            instanceRef.current.renderData(data);

            function generateData(count) {
                var data = [];
                for (let i = 0; i < count; i++) {
                    let val = Math.random() * 100
                    data.push({
                        x: random(0, image.width),
                        y: random(0, image.height),
                        velX: random(-0.5, 0.25),
                        velY: random(-0.5, 0.25),
                        value: val,
                        label: "label_" + i
                    });
                }
                return data;
            }



            function random(min, max) {
                var num = (Math.random() * (max - min)) + min;
                return num;
            }
            // const data = convertToHeatmapData(vehicles, pedestrians, image.width, image.height);
            // instanceRef.current.renderData(data);
        }


        return () => {

        };
    }, [imageUrl, image]);
    console.log({ instanceRef, imageUrl, canvasRef })
    return (
        <div ref={canvasRef} style={{ width: '80vw', height: '80vh' }} />
    );
}

export default HeatmapComp;