import { useState, Fragment, useEffect } from "react"
import { Table } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { useLoading } from "hooks/useLoading"
import { ExportToCsv, LoadingWrapper } from "components"
import { gridMatrixFn, transformTransitData } from "utils/functions"
import { buildReportLink } from "../Reports"
import LargeCard from "components/Structure/LargeCard"
import { set } from "utils/data"
import { readReportParamsFromQuery } from "../Reports"
import ReportFilters from "components/Reports/ReportFilters"
import { ArrowBack } from "@material-ui/icons"
import useAPI from "services/ApiService"
import useStoreDateRange from "hooks/useStoreDateRange"

export const OriginDestinationReport = () => {
    const dateRange = useStoreDateRange()
    const api = useAPI()

    // Read Report type
    const reportType = window.location.pathname.split("/")[2]

    // Read report parameters from the URL
    const [params, updateParams] = useState(readReportParamsFromQuery())
    const [filteredData, setFilteredData] = useState(null)
    const [mappedData, setMappedData] = useState(null)
    const [csv, setCsv] = useState([])

    // Load the data
    const [camera] = useLoading(() => api.camera(params))
    const [report, reportLoadingState] = useLoading(() =>
        api.getOriginDestinationReport(params.systemID, params.cameraID, dateRange, params.carriageways, params.lanes, params.vehicles),
        [params]
    )
    console.log(report)

    useEffect(() => {
        if (report) {
            setFilteredData(report?.entries?.filter(entry => entry?.transit_point?.type?.toLowerCase()?.includes('entry')))
            const data = transformTransitData(report?.entries)
            setMappedData(data)
        }

    }, [report])

    // CSV table
    useEffect(() => {
        let arr = ['Entry'];
        if (filteredData && mappedData) {
            mappedData.map(data => arr.push(data.name))
            setCsv([arr])
            // filteredData?.map(data => setCsv(prev => [...prev, [data?.transit_point?.lane?.name, mappedData?.map(item => gridMatrixFn(data?.flows, item))]]))
            filteredData?.map(data => {
                let newArr = [data?.transit_point?.lane?.name]
                mappedData?.map(item => newArr.push(gridMatrixFn(data?.flows, item)))
                setCsv(prev => [...prev, newArr])
            })
        }
    }, [filteredData, mappedData])

    // The header options
    const headerOptions = (
        <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
            <NavLink
                className="mr-5"
                to={buildReportLink(reportType, {
                    systemID: params.systemID,
                    cameraID: params.cameraID,
                    carriageways: params.carriageways,
                    lanes: params.lanes,
                    vehicles: params.vehicles,
                    range: params.range
                }, true)}
            >
                <ArrowBack /> Back to reports generator
            </NavLink>

            <ExportToCsv data={csv} filename="OD Matrix - Counts" />
        </div>
    )

    return (
        <LargeCard title="OD Matrix - Counts" headerOptions={headerOptions}>
            {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

            <LoadingWrapper
                state={reportLoadingState}
                onEmpty={"There is no data to show for the supplied filter"}
            >
                {filteredData && filteredData?.length > 0 ? (
                    <>
                        <Table bordered hover={false} className="v-align">
                            <thead>
                                <tr>
                                    <th>Entry</th>
                                    {mappedData?.map((item, i) => <th key={i}>{item?.name} Exit</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData?.map((item, i) => <tr key={i}>
                                    <td>{item?.transit_point?.lane?.name}</td>
                                    {mappedData?.map((data, i) => <td key={i}>{gridMatrixFn(item?.flows, data)}</td>)}
                                </tr>)}
                            </tbody>
                        </Table>
                    </>

                ) : (
                    <h1 className="text-center my-10">No events captured yet</h1>
                )}
            </LoadingWrapper>
        </LargeCard>
    )
}
