import React, { useState, useCallback } from 'react';
import { ArrowUpRight, ArrowDownRight, ArrowUpLeft, ArrowDownLeft, Car, Truck, Bike } from 'lucide-react';
import { FormText } from 'components/Reports/Fields';

const transformData = (rawData) => {
    if (!rawData || !Array.isArray(rawData.entries)) {
        console.error('Invalid or missing data structure');
        return { points: [], paths: [], roads: [] };
    }

    const points = [];
    const paths = [];
    const roads = new Set();

    rawData.entries.forEach(entry => {
        if (!entry || !entry.transit_point) return;

        const { transit_point, count, avg_speed } = entry;
        const { uuid, xLane, yLane, type, lane, carriageway } = transit_point;

        if (!lane || !lane.name) return;

        const roadDirection = lane.name.split(' - ').pop().toLowerCase();
        roads.add(roadDirection);

        points.push({
            name: lane.name,
            x: xLane * 600,
            y: yLane * 400,
            type: type.toLowerCase(),
            direction: roadDirection
        });

        if (Array.isArray(entry.flows)) {
            entry.flows.forEach(flow => {
                if (!flow || !flow.transit_point) return;

                const endPoint = rawData.entries.find(e => e.transit_point && e.transit_point.uuid === flow.transit_point.uuid);
                if (endPoint && endPoint.transit_point && endPoint.transit_point.lane) {
                    paths.push({
                        start: lane.name,
                        end: flow.transit_point.lane.name,
                        count: flow.count || 0,
                        avg_speed: flow.avg_speed || 0,
                        color: `hsl(${Math.random() * 360}, 70%, 50%)`,
                        vehicles: flow.vehicles.reduce((acc, v) => {
                            if (v && v.vehicle_type) {
                                acc[v.vehicle_type] = v.count || 0;
                            }
                            return acc;
                        }, {})
                    });
                }
            });
        }
    });

    return { points, paths, roads: Array.from(roads) };
};

const TrafficFlowDiagram = ({ data: rawData }) => {
    const [selectedPath, setSelectedPath] = useState(null);
    const data = transformData(rawData);

    const getPointByName = (name) => data.points.find(p => p.name === name);

    const getPathD = (start, end, curvature = 0.3) => {
        const startPoint = getPointByName(start);
        const endPoint = getPointByName(end);
        if (!startPoint || !endPoint) return '';
        const midX = (startPoint.x + endPoint.x) / 2;
        const midY = (startPoint.y + endPoint.y) / 2;
        const dx = endPoint.x - startPoint.x;
        const dy = endPoint.y - startPoint.y;
        const nx = -dy;
        const ny = dx;
        const controlX = midX + curvature * nx;
        const controlY = midY + curvature * ny;
        return `M${startPoint.x},${startPoint.y} Q${controlX},${controlY} ${endPoint.x},${endPoint.y}`;
    };

    const calculateStrokeWidth = (count) => {
        return Math.pow(count / 100, 0.5) * 2;
    };

    const handlePathClick = useCallback((path, e) => {
        e.stopPropagation();
        console.log("Path clicked:", path);
        setSelectedPath(path);
    }, []);

    const renderPath = (path) => {
        const pathD = getPathD(path.start, path.end);
        const strokeWidth = calculateStrokeWidth(path.count);

        return (
            <g key={`${path.start}-${path.end}`} onClick={(e) => handlePathClick(path, e)}>
                <path d={pathD} stroke={path.color} strokeWidth={strokeWidth} fill="none" opacity="0.7" />
                <path d={pathD} stroke="white" strokeWidth={strokeWidth - 2} fill="none" opacity="0.3" />
            </g>
        );
    };

    const renderSelectedPathInfo = () => {
        console.log("Current selectedPath:", selectedPath);
        if (!selectedPath) return null;
        return (
            <foreignObject x="10" y="410" width="580" height="130">
                <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.2)', fontSize: '12px' }}>
                    <h3 style={{ fontSize: '14px', marginTop: '0' }}>Flow Details</h3>
                    <p><strong>From:</strong> {selectedPath.start}</p>
                    <p><strong>To:</strong> {selectedPath.end}</p>
                    <p><strong>Total Count:</strong> {selectedPath.count.toLocaleString()}</p>
                    <p><strong>Average Speed:</strong> {selectedPath.avg_speed.toFixed(2)} km/h</p>
                    <h4 style={{ fontSize: '13px', marginBottom: '5px' }}>Vehicle Types:</h4>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {Object.entries(selectedPath.vehicles).map(([type, count]) => (
                            <div key={type} style={{ margin: '2px', padding: '3px', backgroundColor: '#f0f0f0', borderRadius: '3px', fontSize: '10px' }}>
                                {type}: {count.toLocaleString()}
                            </div>
                        ))}
                    </div>
                </div>
            </foreignObject>
        );
    };

    const renderRoad = () => {
        const center = { x: 300, y: 200 };
        const roadWidth = 80;
        const roadLength = 200;

        return (
            <g>
                {data.roads.includes('eastwards') && (
                    <g>
                        <rect x={center.x} y={center.y - roadWidth / 2} width={roadLength} height={roadWidth} fill="#808080" />
                        <line x1={center.x} y1={center.y} x2={center.x + roadLength} y2={center.y} stroke="white" strokeWidth="2" strokeDasharray="10 10" />
                    </g>
                )}
                {data.roads.includes('westwards') && (
                    <g>
                        <rect x={center.x - roadLength} y={center.y - roadWidth / 2} width={roadLength} height={roadWidth} fill="#808080" />
                        <line x1={center.x - roadLength} y1={center.y} x2={center.x} y2={center.y} stroke="white" strokeWidth="2" strokeDasharray="10 10" />
                    </g>
                )}
                {data.roads.includes('northwards') && (
                    <g>
                        <rect x={center.x - roadWidth / 2} y={center.y - roadLength} width={roadWidth} height={roadLength} fill="#808080" />
                        <line x1={center.x} y1={center.y - roadLength} x2={center.x} y2={center.y} stroke="white" strokeWidth="2" strokeDasharray="10 10" />
                    </g>
                )}
                {data.roads.includes('southwards') && (
                    <g>
                        <rect x={center.x - roadWidth / 2} y={center.y} width={roadWidth} height={roadLength} fill="#808080" />
                        <line x1={center.x} y1={center.y} x2={center.x} y2={center.y + roadLength} stroke="white" strokeWidth="2" strokeDasharray="10 10" />
                    </g>
                )}
                <circle cx={center.x} cy={center.y} r={roadWidth / 2} fill="#808080" />
            </g>
        );
    };

    const renderDirectionLabels = () => {
        const labels = {
            eastwards: { x: 580, y: 200, textAnchor: "end" },
            westwards: { x: 20, y: 200, textAnchor: "start" },
            northwards: { x: 300, y: 20, textAnchor: "middle" },
            southwards: { x: 300, y: 380, textAnchor: "middle" }
        };

        return (
            <>
                {data.points.map(point => {
                    const labelConfig = labels[point.direction];
                    if (labelConfig) {
                        return (
                            <text key={point.name} x={labelConfig.x} y={labelConfig.y} textAnchor={labelConfig.textAnchor} fill="black" fontSize="10" fontWeight="bold">
                                {point.name}
                            </text>
                        );
                    }
                    return null;
                })}
            </>
        );
    };

    const renderContext = () => (
        <foreignObject x="10" y="10" width="580" height="50">
            <div style={{ backgroundColor: 'rgba(255,255,255,0.8)', padding: '5px', borderRadius: '5px', fontSize: '12px' }}>
                <p>This diagram shows traffic flow between different points in Mġarr.
                    Thicker lines indicate higher traffic volume.
                    Green circles are entry points, red circles are exit points.
                    Click on a path to see detailed flow information.</p>
            </div>
        </foreignObject>
    );

    const handleSvgClick = useCallback(() => {
        console.log("SVG clicked, clearing selectedPath");
        setSelectedPath(null);
    }, []);

    return (
        <>
            <FormText>
                <p>This diagram shows traffic flow between different points.
                    Thicker lines indicate higher traffic volume.
                    Green circles are entry points, red circles are exit points.
                    Click on a path to see detailed flow information.</p>
            </FormText>
            <svg width="100%" height="100vh" viewBox="0 0 600 550" onClick={handleSvgClick}>
                <rect x="0" y="0" width="600" height="550" fill="#E6E6E6" />
                <pattern id="grid" width="20" height="20" patternUnits="userSpaceOnUse">
                    <path d="M 20 0 L 0 0 0 20" fill="none" stroke="#D3D3D3" strokeWidth="0.5" />
                </pattern>
                <rect x="0" y="0" width="600" height="400" fill="url(#grid)" />

                {renderRoad()}
                {data.paths.map(renderPath)}

                {data.points.map((point) => (
                    <circle key={point.name} cx={point.x} cy={point.y} r="6" fill={point.type === 'entry' ? '#2ecc71' : '#e74c3c'} stroke="white" strokeWidth="1" />
                ))}

                {renderDirectionLabels()}
                {renderSelectedPathInfo()}

                <rect x="480" y="10" width="110" height="80" fill="white" opacity="0.8" rx="5" />
                <circle cx="490" cy="25" r="4" fill="#2ecc71" />
                <text x="500" y="28" fill="black" fontSize="10">Entries</text>
                <circle cx="490" cy="45" r="4" fill="#e74c3c" />
                <text x="500" y="48" fill="black" fontSize="10">Exits</text>
                <Truck size={12} x="490" y="60" />
                <Car size={12} x="510" y="60" />
                <Bike size={12} x="530" y="60" />
                <text x="535" y="80" fill="black" fontSize="8">Vehicles</text>
            </svg>
        </>

    );
};

export default TrafficFlowDiagram;