import { Fragment, useState } from "react"
import { Table } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { buildReportLink } from "../Reports"
import { useLoading } from "hooks/useLoading"
import { ExportToCsv, LoadingWrapper } from "components"
import { humanNumber, humanPercent, humanSpeed, humanTotal } from "utils/functions"
import LargeCard from "components/Structure/LargeCard"
import ReportFilters from "components/Reports/ReportFilters"
import { csvAvgSpeed } from "utils/csv"
import { readReportParamsFromQuery } from "../Reports"
import { ArrowBack } from "@material-ui/icons"
import useAPI from "services/ApiService"
import useStoreDateRange from "hooks/useStoreDateRange"
import { humanDate, humanDayTime } from "utils/time"
import { IoIosArrowForward } from "react-icons/io";
import '../../../../page.scss'
import { Select } from "components/Reports/Fields"

export const FDSByCarriageway = () => {
    const dateRange = useStoreDateRange()
    const api = useAPI()

    // Read Report type
    const reportType = window.location.pathname.split("/")[2]

    // Read report parameters from the URL
    const [params, updateParams] = useState(readReportParamsFromQuery())

    // Load the data
    const [camera] = useLoading(() => api.camera(params))
    const [report, reportLoadingState] = useLoading(() =>
        api.getFDSCarriageWayMetrics(params.systemID, params.cameraID, dateRange),
        [params]
    )
    const [current, setcurrent] = useState(0)

    // CSV table
    const csv = []
    report?.data?.map((item) => {
        item.data.map(row => csv.push([
            item.carriageway_name,
            humanDate(row.start),
            `${humanDayTime(row.start)} - ${humanDayTime(row.end, true)}`,
            row.flow_rate,
            csvAvgSpeed(row.avg_speed),
            humanNumber(row.density, 3),
            humanPercent(row.occupancy_percentage),
            humanPercent(row.congestion_percentage)
        ]))
    })
    csv?.unshift(["carriageway name", "Date", "Time", "Flow rate", "Avg speed [km/h]", "Density", "Occupancy [%]", "Congestion [%]"])

    console.log({ report })
    // The header options
    const headerOptions = (
        <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
            <NavLink
                className="mr-5"
                to={buildReportLink(reportType, {
                    systemID: params.systemID,
                    cameraID: params.cameraID,
                    range: params.range
                }, true)}
            >
                <ArrowBack /> Back to reports generator
            </NavLink>
            <ExportToCsv data={csv} filename="Flow Density Occupancy report by carriageway" />
        </div>
    )

    return (
        <LargeCard title="Flow Density Occupancy report by carriageway" headerOptions={headerOptions}>

            {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

            <LoadingWrapper state={reportLoadingState} onEmpty={"There is no data to show for the supplied filter"}>
                {report && <div style={{ paddingLeft: 0 }} className={`${"col-xl-4 col-md-6"}`}>
                    <Select
                        state={[current, setcurrent]}
                        name="Carriageway"
                        options={report?.data?.map((carriageway, ind) => [ind, carriageway.carriageway_name])}
                    />
                </div>}
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th width="15%">Date</th>
                            <th width="15%">Time</th>
                            <th width="10%">Flow rate</th>
                            <th width="15%">Avg speed</th>
                            <th width="15%">Density</th>
                            <th width="15%">Occupancy %</th>
                            <th width="15%">Congestion %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {report && report?.data[current].data.map((row, i) => (
                            <tr key={`${i}`}>
                                <td>{humanDate(row.start)}</td>
                                <td>{humanDayTime(row.start)} - {humanDayTime(row.end, true)}</td>
                                <td>{row.flow_rate}</td>
                                <td>{humanSpeed(row.avg_speed)}</td>
                                <td>{humanNumber(row.density, 3)}</td>
                                <td>{humanPercent(row.occupancy_percentage)}</td>
                                <td>{humanPercent(row.congestion_percentage)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </LoadingWrapper>
        </LargeCard>
    )
}
