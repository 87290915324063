import moment from "moment"

/**
 * Easy prevent default.
 *
 * @param event The original event.
 *
 * @return {boolean} Always true.
 */
export const noDefault = (event) => {
  event.preventDefault && event.preventDefault()
  event.stopPropagation && event.stopPropagation()
  return true
}

/**
 * Randomly shuffle the array.
 *
 * @param {[]} array The array to shuffle.
 *
 * @return {[]} The array with the same elements, but in a random order.
 */
export const shuffle = (array) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

/**
 * Insert a string into another string at a specified position.
 *
 * @param {string} target The string to insert into.
 * @param {int} index Where to insert the string.
 * @param {string} value The string to insert.
 *
 * @return {string} The original string with the inserted value as well.
 */
export const insert = (target, index, value) =>
  (target != null ? target.substring(0, index) + value + target.substr(index) : null)

/**
 * Get the total sum value from a list ot items at a specific key.
 *
 * @param {any[]} items The items to aggregate.
 * @param {string} key The key whose values to sum.
 *
 * @return {number} The sum of all values.
 */
export const totalValue = (items, key) =>
  items.reduce((acc, item) => acc + item[key], 0)

/**
 * Get the average value from a list ot items at a specific key.
 *
 * @param {any[]} items The items to iterate over.
 * @param {string} key The key whose values to average.
 *
 * @return {number} The average of all values.
 */
export const averageValue = (items, key) =>
  (items.length > 0 ? Math.round(parseFloat(totalValue(items, key) / items.length)) : 0)

/**
 * Append the english ordinal suffix to a number.
 *
 * @param {number} num The number to add ordinal suffix to.
 *
 * @return {string} The supplied number with one of the following suffixes: "th", "st", "nd, "rd".
 */
export const humanOrdinal = (num) =>
  num + (["st", "nd", "rd"][((((Math.round(Math.abs(num)) + 90) % 100) - 10) % 10) - 1] || "th")

/**
 * Check if the passed boolean, string, object or array is empty.
 * All other types are considered never to be empty.
 *
 * @param {any|null} value The value to test if it is empty.
 *
 * @return {boolean} True if the supplied value is considered an empty value.
 */
export const isEmpty = (value) => {

  // Undefined or null
  if (value == null) {
    return true
  }

  // Boolean
  if (typeof value === "boolean") {
    return !value
  }

  // String
  if (typeof value === "string") {
    return value.trim() === ""
  }

  // Array
  if (Array.isArray(value)) {
    return value.length === 0
  }

  // Object: if at least one key is found it is not empty
  if (typeof value === "object") {
    return Object.getPrototypeOf(value) === Object.prototype &&
      Object.getOwnPropertySymbols(value).length === 0 &&
      Object.getOwnPropertyNames(value).length === 0

  }

  return false
}

/**
 * Check if the passed boolean, string, object or array is not empty.
 * All other types are considered never to be empty.
 *
 * @param {any|null} value The value to test if it is not empty.
 *
 * @return {boolean} True if the supplied value is considered a non-empty value.
 */
export const isNotEmpty = (value) =>
  !isEmpty(value)

/**
 * Show a human friendly number.
 *
 * @param {number} number The number to format.
 * @param {int} decimals The number of decimals to include.
 *
 * @return {string} The formatted number.
 */
export const humanNumber = (number, decimals = 0) => {
  if (number == null) {
    return null
  }

  // Ignore strings
  if (typeof number === "string") {
    return number
  }

  // Round to the required decimal places
  let num = number.toFixed(decimals)

  // Calculate the positions of radixes
  const len = num.indexOf(".") > -1 ? num.indexOf(".") : num.length
  let pos = len % 3
  for (let count = Math.floor(len / 3); count; count--) {
    // Do not place radix in front of the number
    if (pos > 0) {
      num = insert(num, pos, ",")

      // Make sure to account the inserted radix in the next position
      pos++
    }

    pos += 3
  }

  return num
}

/**
 * Format the speed for humans.
 *
 * @param {number} speed The original speed to format.
 * @param {string} defaults The string to return if the value is not supplied.
 *
 * @return {string} The rounded speed with the unit of measurements included.
 */
export const humanSpeed = (speed, defaults = " ") =>
  (speed != null && !isNaN(speed) && speed > 0 ? speed.toFixed(0) + " km/h" : defaults)

/**
 * Format the percentage for humans.
 *
 * @param {number} value The original percent to format.
 * @param {string} defaults The string to return if the value is not supplied.
 *
 * @return {string} The rounded percent with the "%" suffix included.
 */
export const humanPercent = (value, defaults = "") =>
  (value != null ? value.toFixed(value < 1 ? (value < 0.1 ? 2 : 1) : 0) + "%" : defaults)

/**
 * Show an emphasized total value.
 *
 * @param {string} value The value to show as total.
 * @param {string} suffix The optional suffix to add after "Total".
 *
 * @return {JSX.Element}
 */
export const humanTotal = (value, suffix = "") =>
  <strong>{`Total ${suffix}: ${value}`}</strong>

/**
 * Build a HTTP GET query from the supplied key-value pairs.
 *
 * @param {{string: string}} data The key-value pars to convert to HTTP query.
 *
 * @return {string} The query string with the '?' prefix included.
 */
export const buildQuery = (data = {}) => {
  let query = ""

  for (const key in data) {
    if (data.hasOwnProperty(key) && isNotEmpty(data[key])) {
      let values = data[key]

      // Handle everything as an array with multiple values
      for (const value of Array.isArray(values) ? values : [values]) {
        if (isNotEmpty(value)) {
          // Use "?" for the first pair and "&" for all others
          query += query.length > 0 ? "&" : "?"

          // Make sure that both key and value are encoded
          query += encodeURIComponent(key) + "=" + encodeURIComponent(value)
        }
      }
    }
  }

  return query
}

/**
 * Make a string with first letter in uppercase and all others in lowercase.
 *
 * @param {string} value The string to change the case.
 *
 * @return {string} The value with the first letter in uppercase and other in lowercase.
 */
export const ucFirst = (value) => {
  value = value.trim()

  // Make sure we have something
  if (value.length === 0) {
    return ""
  }

  return value.substr(0, 1).toUpperCase() + value.substr(1).toLowerCase()
}

/**
 * Make all words in a string start with an uppercase.
 *
 * @param {string} value The string to change the case.
 *
 * @return {string} The value with the first letter in uppercase and other in lowercase.
 */
export const ucWords = (value) =>
  value
    .split(" ")
    .map(ucFirst)
    .join(" ")

/**
 * Return the array containing only unique values in the array.
 *
 * @param {*[]} array The original array.
 *
 * @return {*[]} The array of unique values.
 */
export const unique = (array: *[]) =>
  array.filter((value, index, self) => self.indexOf(value) === index)

/**
 * Return the array of objects containing only unique object values.
 *
 * @param {*[]} array The original array.
 *
 * @return {*[]} The array of unique inject values.
 */

export const uniqueArrOfObj = (array) =>
  array.filter((value, index) => {
    const _value = JSON.stringify(value)
    return (
      index ===
      array.findIndex((obj) => {
        return JSON.stringify(obj) === _value
      })
    )
  })

/**
 * Convert file blob to data.
 *
 * @param {Blob} file The original file.
 * @return {Promise} File converted to Data URL.
 */

export const blobToData = (file: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(file)
  })
}

/** 
 * Convert seconds to hours, min , day, etc depending of the value of seconds
 * @param {number} seconds Number in seconds
 * @return {string} Value in seconds, minutes, hours or day
 */
export const getReadableTime = (seconds: number) => {
  const d = Math.floor(seconds / (3600 * 24));

  seconds -= d * 3600 * 24;

  const h = Math.floor(seconds / 3600);

  seconds -= h * 3600;

  const m = Math.floor(seconds / 60);

  seconds -= m * 60;

  const tmp = [];

  (d) && tmp.push(d + 'd');

  (d || h) && tmp.push(`${h}hr${h > 1 ? 's' : ''}`);

  (d || h || m) && tmp.push(`${m}min${m > 1 ? 's' : ''}`);

  tmp.push(Math.floor(seconds) + 's');

  return tmp.join(' ');
}

/**
 * 
 * @param {range} dateRange range between start and end date
 * @returns Return boolean indicating if the date is more than 7 days
 */
export const getValidTime = (dateRange) => {
  const date = (new Date(dateRange.end) - new Date(dateRange.start)) / 1000
  const maxDate = 24 * 60 * 60 * 7
  return date > maxDate
}

/**
 * 
 * @returns Returns the time from yesterday - present time
 */
export const getYesterdayDate = () => {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  return { start: date, end: new Date() }
}

/**
 * A function to load the last 24hrs of a given date range if the range is greater than 24hrs.
 * @param {any} dateRange The dateRange
 * @returns The daterange, either the last 24 hours or the full date range.
 */
export const loadLast24Hrs = (dateRange, check) => {
  let isLessthan24 = false;
  const { start, end } = dateRange
  // Parse the input dates using Moment.js
  const startDate = moment(start, 'DD/MM/YYYY HH:mm');
  const endDate = moment(end, 'DD/MM/YYYY HH:mm');

  // Calculate the difference in hours between the end and start dates
  const hoursDifference = endDate.diff(startDate, 'hours');

  // If the difference is > 24 hours, return the last 24 hours
  if (hoursDifference > 24) {
    const last24HoursEnd = endDate.format('YYYY-MM-DDTHH:mm');
    const last24HoursStart = endDate.clone().subtract(24, 'hours');
    isLessthan24 = false;
    if (check) {
      return {
        start: last24HoursStart,
        end: last24HoursEnd,
        isLessthan24,
        globalRange: dateRange
      };
    }
    return {
      start: startDate.format('YYYY-MM-DDTHH:mm'),
      end: endDate.format('YYYY-MM-DDTHH:mm'),
      isLessthan24,
      globalRange: dateRange
    }
  }
  else {
    isLessthan24 = true;
    return {
      start: startDate.format('YYYY-MM-DDTHH:mm'),
      end: endDate.format('YYYY-MM-DDTHH:mm'),
      isLessthan24,
      globalRange: dateRange
    }
  }
}
export function isDateRangeMoreThan30Days(dateRange) {
  const start = moment(dateRange.start);
  const end = moment(dateRange.end);

  const daysDifference = end.diff(start, 'days');

  return daysDifference > 30;
}

/**
 * 
 * @param {Date} local trajectories local date range
 * @param {Date} global global date range
 * @return {boolean} return True if start and end date is same, else false
 */
export function sameDate(dateRange1, dateRange2, unit = 'minutes') {
  const start1 = moment(dateRange1.start);
  const end1 = moment(dateRange1.end);

  const start2 = moment(dateRange2.start);
  const end2 = moment(dateRange2.end);

  const duration1 = end1.diff(start1, unit);
  const duration2 = end2.diff(start2, unit);

  return duration1 === duration2;
}

/**
 * Checks if the entry contains the particular direction. return the count if it does, returns 0 if it does not.
 * @param {any[]} item Exit array 
 * @param {string} direction Whether East, West, North or South
 * @return {number} returns the count if the function returns true, 0 if it returns false
 */
export const gridMatrixFn = (item, direction) => {
  let count = 0;

  item?.map(data => {
    if (data?.transit_point?.lane?.uuid === direction?.uuid) {
      count = data?.count
      return count
    }
  })
  return count
}

/**
 * 
 * @param {any} transit_data The transit data gotten from the transit flow API
 * * @param {string} direction Whether East, West, North or South
 * * @param {any} pcu_data The pcu data gotten from the pcu API
 * * @param {string[]} vehicle_filter Array of filter of the vehicles
 * @returns {any[]} array of the data to be shown. gives the capability of filtering
 */
export const getPcuValue = (transit_data, direction, pcu_data, vehicle_filter) => {
  let count = 0;
  transit_data?.map(data => {
    let temp = 0;
    if (data?.transit_point?.lane?.uuid === direction?.uuid) {

      data?.vehicles.filter(veh => vehicle_filter?.includes(veh?.vehicle_type) || vehicle_filter?.length === 0)?.map(item => {
        pcu_data?.filter(dat => dat?.vehicle_type_label === item.vehicle_type).map(ele => {
          temp += ele?.pcu * item.count
        })
      })
      count = Math.floor(temp)
      return count;
    }
  })
  return count
}

/**
 * 
 * @param {any} vehicle_data The transit data gotten from the vehicle breakdown API
 * * @param {any} pcu_data The pcu data gotten from the pcu API
 * @returns {any[]} PCU count
 */
export const getPcuValueForReport = (vehicle_data, pcu_data) => {
  let count = 0;
  vehicle_data?.map(data => {
    let pcu_val = pcu_data.find(veh => veh.vehicle_type_label === data.vehicle_type);
    count += pcu_val?.pcu * data.count
    // pcu_data.filter(veh => veh.vehicle_type_label === data.vehicle_type)?.map(item => {
    //   count += item?.pcu * data.count
    // })
  })
  return count?.toFixed(1)
}



/**
 * Get all exist points present in the data
 * @param {any[]} transit_data The transit data gotten from the transit flow API
 * @return {any[]} returns an array of data containing the exit lanes and its uuid
 */
export const transformTransitData = (transit_data) => {
  let exit_arr = [];
  let exit_name = [];

  transit_data?.map(item =>
    item?.flows?.map(res => {
      if (!exit_name.includes(res?.transit_point?.lane?.name) && res?.transit_point?.type?.toLowerCase()?.includes('exit')) {
        exit_arr.push(res?.transit_point?.lane)
        exit_name.push(res?.transit_point?.lane?.name)
      }
      return exit_arr
    }
    ))
  return exit_arr
}

/**
 * Get all exist points present in the data
 * @param {any[]} transit_data The transit data gotten from the transit flow API
 * @return {any[]} returns an array of data containing the exit lanes and its uuid
 */
export const transformMultiTransitData = (transit_data) => {
  let exit_arr = [];
  let exit_name = [];

  transit_data?.map(item =>
    item?.flows?.map(res => {
      if (!exit_name.includes(res?.transit_point?.uuid) && res?.transit_point?.type?.toLowerCase()?.includes('exit')) {
        exit_arr.push(res?.transit_point?.lane)
        exit_name.push(res?.transit_point?.uuid)
      }
      return exit_arr
    }
    ))
  return exit_arr
}

/**
 * Add each events as a row for csv data
 * @param {any[]} transit_data The transit data gotten from the transit flow API
 * @return {any[]} returns an array of data containing the csv data 
 */
export const eventListToCsv = (transit_data, csv) => {
  let new_csv = [csv]

  transit_data?.map(data =>
    data?.vehicles?.map(item => new_csv.push([`${moment(item?.start_ts).format('DD-MM-YYYY')}`,
    `${moment(item?.start_ts).format('H:mm:ss')} - ${moment(item?.end_ts).format('H:mm:ss')}`,
    data?.zone?.name, data?.zone?.name,
    item?.vehicle_type?.type,
    getReadableTime(item?.duration_seconds)])
    ))

  return new_csv
}

/**
 * @param {any[]} entries The report entry lanes
 * @return {any[]} Returns all possible routes of the entry-exit lanes
 */
export const getReportColumn = (entries) => {
  let col_header = [];
  entries.map(entry => entry?.flows.map(item => {
    if (!col_header.includes(item?.transit_point?.lane?.name)) {
      col_header.push(`${item?.transit_point?.lane?.name}`)
    }
  }

  ))
  return { col_header }
}

/**
 * @param {any[]} granular_data The data to filter through the get the vehicle type counts
 * @param {any[]} vehicle_type the vehicle type to get the count
 * @return {number} The count to return if vehicle type is found. returns 0 if there's no vehicle type
 */
export const getMCReportCount = (granular_data, vehicle_type) => {
  let count = 0;
  granular_data?.map(item =>
    item?.flows?.map(flow => {
      const res = flow?.vehicles?.find(vehicle => vehicle?.vehicle_type === vehicle_type.type)
      count = res?.count ?? 0;
      return count
    }))

  return count
}

/**
 * @param {any[]} granular_data The data to filter through the get the vehicle type counts
 * @return {number} The total count of all vehicles. returns 0, if no vehicle is found.
 */
export const getMCReportTotal = (granular_data) => {
  let total = 0;
  granular_data?.map(item =>
    item?.flows?.map(flow => {
      total = flow?.count ?? 0;
      return total
    }))

  return total
}

/**
 * @param {any[]} granular_data The data to filter through the get the vehicle type counts
 * @param {any[]} pcu_data Pcu data containing the values for each vehicle type.
 * @return {number} The Pcu total count to return. returns 0 if there's no vehicle found.
 */
export const getMCReportPcu = (granular_data, pcu_data) => {
  let pcu_val = 0;
  granular_data?.map(item =>
    item?.flows?.map(flow => {
      pcu_val = getPcuValueForReport(flow?.vehicles, pcu_data)
      return pcu_val
    }))

  return pcu_val
}

/**
 * 
 */

/**
 * @param vehicle The Type of vehicle to find
 * @param vehicles The granular data of the report, showing the vehicles 
 * @return Returns the count for the particular vehicle
 */
export const reportVehicleSummary = (vehicle, vehicles) => {
  let table_col;
  table_col = vehicles?.find(res => res?.name?.toLowerCase() === vehicle?.toLowerCase())
  return table_col
}

/**
 * 
 * @param {any} transit_data The transit data gotten from the transit flow API
 * @param {string} api return the result based on the selected api
 * @returns {any[]} array of the data to be shown. gives the capability of filtering
 */
export const setMappedData = (transit_data, api, vehicles) => {
  let map_data = [];

  if (api === 'vehicles-stopped-list') {
    let temp = []
    transit_data?.map(item => item?.vehicles?.map(res => {
      let new_obj = { ...res, ...item.zone }
      temp.push(new_obj)
    })
    )
    map_data = temp;
  }
  else if (vehicles.includes(api)) {
    let temp = []
    transit_data?.map(data => data?.vehicles?.map(item => {
      let new_obj
      item?.data?.map(res => {
        new_obj = { ...res, vehicle: item?.vehicle_type?.type, ...data?.zone }
        temp.push(new_obj)
      })
    })
    )
    map_data = temp;
  }
  else if (api === "vehicles-stopped-count-granular") {
    let temp = []
    transit_data?.map(item => item?.data?.map(res => {
      let new_obj = { ...res, ...item.zone }
      temp.push(new_obj)
    })
    )
    map_data = temp;
  }
  else {
    map_data = transit_data?.map(data => {
      return { ...data?.data, zone: data?.zone }
    })
  }
  return map_data;
}

/**
 * 
 * @param {string} filter Name of column you wish to filter
 * @param {array} mapData Array to be filtered
 * @param {boolean} columnOrder Check if the order is ascending or descending
 * @return {object} return an object containing the sorted data and updated column order
 */
export const handleFilterData = (filter, mapData, columnOrder) => {
  let col_order = !columnOrder
  let sorted_data
  if (filter === 'duration') {

    if (!columnOrder) {
      sorted_data = mapData?.sort((a, b) => a?.duration_seconds < b?.duration_seconds ? 1 : a?.duration_seconds > b?.duration_seconds ? -1 : 0)
    }
    else {
      sorted_data = mapData?.sort((a, b) => a?.duration_seconds > b?.duration_seconds ? 1 : a?.duration_seconds < b?.duration_seconds ? -1 : 0)
    }
  }
  return { sorted_data, col_order }
}

/**
 * This function takes a Konva node as input and returns an array of four coordinates that represent the corners of the node, relative to the stage.
 * @param {any} node A Konva node
 * @return {number[]} returns an the vertices array. This is a 2d array, with each point given in its x and y coordinate
 *  */
export const getEdgeCoords = (node) => {
  // Calculate the absolute position of the top-left corner of the node
  const absTopLeft = node.absolutePosition();

  // Calculate the absolute position of the bottom-right corner of the node by adding the width and height of the node to the top-left corner
  const absBottomRight = {
    x: absTopLeft.x + node.width(),
    y: absTopLeft.y + node.height(),
  };

  // Calculate the relative position of the top-left corner by dividing its absolute position by the width and height of the stage, respectively
  const relTopLeft = [
    absTopLeft.x / node.getStage().width(),
    absTopLeft.y / node.getStage().height(),
  ]

  // Calculate the relative position of the bottom-right corner by dividing its absolute position by the width and height of the stage, respectively
  const relBottomRight = [
    absBottomRight.x / node.getStage().width(),
    absBottomRight.y / node.getStage().height(),
  ];

  // Return an array of four coordinates, starting with the relative top-left corner and proceeding clockwise to the other corners
  return [
    relTopLeft,
    [relBottomRight[0], relTopLeft[1]],
    relBottomRight,
    [relTopLeft[0], relBottomRight[1]],
  ];
};

/**
 * This function takes a Konva node as input and returns an array of four coordinates that represent the corners of the node, relative to the stage.
 * @param {any} node A Konva node
 * @return {number[]} returns an the vertices array. This is a 2d array, with each point given in its x and y coordinate
 *  */
export const getRectVertices = (node, scaleX, scaleY, stage_width, stage_height) => {
  const x = node.x();
  const y = node.y();
  const width = Math.max(5, node.width() * scaleX);
  const height = Math.max(node.height() * scaleY);
  const relTopLeft = [x / stage_width, y / stage_height]
  const relBottomRight = [(x + width) / stage_width, (y + height) / stage_height]

  return [
    relTopLeft,
    [relBottomRight[0], relTopLeft[1]],
    relBottomRight,
    [relTopLeft[0], relBottomRight[1]],
  ];
}

export const parseVal = (val) => {
  return parseFloat(val.toFixed(3))
}
/**
 * 
 * @param {any} relative_values The relative values of the rectangle variables.
 * @return returns the vertices as a 2d array. the inner array represents the (x,y) coordinate of each edge of the triangle.
 */
export const getVerticesCoords = (relative_values) => {
  const { left, right, top, bottom } = relative_values
  return [
    [parseVal(left), parseVal(top)],
    [parseVal(right), parseVal(top)],
    [parseVal(right), parseVal(bottom)],
    [parseVal(left), parseVal(bottom)]
  ]
};
/**
 * 
 * @param {any[]} arr Array of object containing zone name and zone type
 * @returns boolean. true if all of the zones have the name and the type filled
 */
export const checkAllObjs = (arr) => {
  let isValid;

  isValid = arr.every(ele => ele?.name !== '' && ele?.type !== '')
  return isValid;
}

/**
 * @param items The absolute values of the rectangle variables.
 * @param width The width of the image (camera view)
 * @param height The height of the image (camera view)
 * @param handleZone Function to set the validity of the zone.
 * @returns boolean. true if it's within the camera, false if it's outside the boundary.
 */
export const checkValidLines = (items, width, height, handleZone) => {
  const { left, right, top, bottom } = items
  let isValid = false;
  if (right >= width - 30 || left < 30 || bottom >= height - 25 || top < 25) {
    isValid = false;
  }
  else {
    isValid = true;
  }
  return { isValid }
}

/**
 * @param sessions The live sessions gotten from the API
 * @return session. Contains both live and non-live sessions which status indicating when it was live and non-live
 */
export function getSessionsWithStatus(sessions) {
  let result = [];

  for (let i = 0; i < sessions.length; i++) {
    let currentSession = sessions[i];

    // Add the current session to the result array with a status of "ON"
    result.push({
      start: currentSession.start,
      end: currentSession.end,
      status: "ON",
      duration: getReadableTime((new Date(currentSession.end) - new Date(currentSession.start)) / 1000)
    });

    // If this isn't the last session, add a non-live session to the result array
    if (i < sessions.length - 1) {
      let nextSession = sessions[i + 1];
      let nonLiveSession = {
        start: currentSession.end,
        end: nextSession.start,
        status: "OFF",
        duration: getReadableTime((new Date(nextSession.start) - new Date(currentSession.end)) / 1000)
      };
      result.push(nonLiveSession);
    }
  }

  // Add the last session to the result array with a status of "OFF"
  // let lastSession = sessions[sessions.length - 1];
  // result.push({
  //   start: lastSession.start,
  //   end: lastSession.end,
  //   status: "OFF",
  //   duration: getReadableTime((new Date(lastSession.end) - new Date(lastSession.start)) / 1000)
  // });

  return result;
}

/**
 * 
 * @param {Object} cameras The cameras to be combined.
 * @param {Class} api The api endpoint function we use the get the lines of each camera
 * @returns An array containing the entry and exit lines of the different cameras
 */
export const getCombinedLines = async (cameras, api) => {
  let entry_lines = [], exit_lines = [], combinedData = [];
  let system;
  for (const camera of cameras) {
    const res = await api.camera(camera.id)
    combinedData.push(res)
    console.log(res)
    system = res?.system
    Object.values(res?.entry_lines?.general).map(item => entry_lines.push({
      ...item,
      camera: res?.camera_name,
    }))
    Object.values(res?.exit_lines?.general).map(item => exit_lines.push({
      ...item,
      camera: res?.camera_name,
    }))
  }

  const entry_res = [...entry_lines.flat()], exit_res = [...exit_lines.flat()];
  const cameraGroup = groupByCamera(entry_res, exit_res)
  return { entry_res, exit_res, system, combinedData, cameraGroup }
}

export function groupByCamera(entry_res, exit_res) {
  const result = {};

  for (const entry of entry_res) {
    const camera = entry.camera;
    if (!result[camera]) {
      result[camera] = {
        camera,
        data: [],
      };
    }
    result[camera].data.push({
      ...entry,
      type: 'ENTRY',
    });
  }

  for (const exit of exit_res) {
    const camera = exit.camera;
    if (!result[camera]) {
      result[camera] = {
        camera,
        data: [],
      };
    }
    result[camera].data.push({
      ...exit,
      type: 'EXIT',
    });
  }

  return Object.values(result);
}


/**
 * 
 * @param {any[]} saved_cam The saved cameras
 * @param {any[]} all_cam All the cameras that can be combined
 * @param {string} itemKey The key to filter the 
 * @returns The resulting array containing cameras that have been saved
 */
export function filterObjectsByItem(saved_cam, all_cam, itemKey = 'camera_id') {
  const resultArray = [];

  for (const obj1 of all_cam) {
    const itemValue = obj1[itemKey];
    const itemFound = saved_cam.some(obj2 => obj2[itemKey] === itemValue);

    if (itemFound) {
      resultArray.push(obj1);
    }
  }
  const res = resultArray.map(camera => {
    return { id: camera.uuid, value: camera.camera_id, label: camera.name, tenant: camera?.system?.toLowerCase() }
  })
  return res;
}

/**
 * 
 * @param {any[]} linesToCombine The saved lines entry uuid and exit uuid
 * @param {any[]} combinedCameras The combined camera of all possible entries and exits
 * @returns The array of entries data and exits data
 */
export function filterCombinedCameras(linesToCombine, combinedCameras) {
  const filteredData = [];

  for (const obj of linesToCombine) {
    const entryUuid = obj.entry_line_uuid;
    const exitUuid = obj.exit_line_uuid;

    const matchingEntry = combinedCameras.entry_res.find(entry => entry.uuid === entryUuid);
    const matchingExit = combinedCameras.exit_res.find(exit => exit.uuid === exitUuid);

    if (matchingEntry && matchingExit) {
      filteredData.push({
        entry: matchingEntry,
        exit: matchingExit
      });
    }
  }
  const res = modifyEntryExitFields(filteredData);
  return res
}

export function modifyEntryExitFields(inputArray) {
  const modifiedArray = [];

  for (const obj of inputArray) {
    const modifiedEntry = {
      id: obj.entry.uuid,
      label: `${obj.entry.lane_name} - (${obj?.entry?.camera})`,
      value: { ...obj.entry }
    };

    const modifiedExit = {
      id: obj.exit.uuid,
      label: `${obj.exit.lane_name} - (${obj?.entry?.camera})`,
      value: { ...obj.exit }
    };

    modifiedArray.push({
      entry: modifiedEntry,
      exit: modifiedExit
    });
  }

  return modifiedArray;
}

/**
 * 
 * @param {string} fileName The filename to check
 * @param {string} supportedExtensions The list of extensions
 * @returns If the file is valid
 */
export function isSupported(fileName, supportedExtensions) {
  const extensions = supportedExtensions.replace(/\./g, " ").trim();
  const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  let instance = extensions.includes(fileExtension)
  return instance;
}

export function gaussian(x, peak, width) {
  return peak * Math.exp(-0.5 * Math.pow((x - 50) / width, 2));
}

export function quadraticCurve(x, a = 0.2, b = 1, c = 0) {
  return a * Math.pow(x, 2) + b * x + c;
}

/**
 * 
 * @param {Object} series The data series to plot
 * @param {*} axis The current x-y axis to plot
 * @returns 
 */
export const createLineSeries = (series, axis, option) => {
  const res = []
  for (const [key, value] of Object.entries(series)) {
    if (axis === 'rate') {
      res.push({ name: key, data: value.rate_density })
    }
    else if (axis === 'speed') {
      res.push({ name: key, data: value.speed_density })
    }
    else if (axis === 'flow') {
      res.push({ name: key, data: value.speed_flow_rate })
    }
    else if (axis === 'ratetime') {
      res.push({ name: key, data: value.flow_rate })
    }
    else if (axis === 'speedtime') {
      res.push({ name: key, data: value.avgSpeed })
    }
    else if (axis === 'densitytime') {
      res.push({ name: key, data: value.density })
    }
    else if (axis === 'occupancy') {
      res.push({ name: key, data: value.occupancy })
    }
    else if (axis === 'congestion') {
      res.push({ name: key, data: value.congestion })
    }
  }
  return res
}

export function aggregateStatsData(data) {
  const res = []
  for (const [key, val] of Object.entries(data)) {
    if (key === 'avgSpeed') {
      res.push({ name: 'average speed', data: val })
    }
    else if (key === 'count') {
      res.push({ name: 'vehicle count', data: val })
    }
    else if (key === 'percentiles') {
      res.push({ name: '85th percentile speed', data: val })
    }
  }
  return res
}

/**
 * 
 * @param {Object} data The Object containing the lanes/carriageway of a camera
 * @param {number} category The current lane/carriageway to get data for
 * @returns The categorized data (avg speed, density,flow rate, occupancy)
 */
export function jointDataByCategory(data, category) {
  const res = []
  const yAxis = []
  for (const [key, val] of Object.entries(data[category])) {
    if (key === 'avgSpeed') {
      res.push({ name: 'average speed', data: val })
      yAxis.push({ seriesName: 'flow rate', show: false })
    }
    else if (key === 'flow_rate') {
      res.push({ name: 'flow rate', data: val })
      yAxis.push({
        seriesName: 'flow rate',
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: ""
        },
        labels: {
          formatter: (val) => {
            return Math.round(val)
          }
        }
      })
    }
    else if (key === 'density') {
      res.push({ name: 'density', data: val })
      yAxis.push({
        opposite: true,
        seriesName: 'density',
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: ""
        }
      })
    }
    else if (key === 'occupancy') {
      res.push({ name: 'occupancy percentage', data: val })
      yAxis.push({
        seriesName: 'density', show: false
      })
    }
    else if (key === 'congestion') {
      res.push({ name: 'congestion percentage', data: val })
      yAxis.push({
        seriesName: 'density', show: false
      })
    }
  }
  return { res, yAxis }
}

/**
 * 
 * @param {string} url A url string to validate. 
 * @returns true if it is a valid rtsp url or .m3u8 url else returns false
 */
export function validateStreamUrl(url) {
  if (url === '') {
    return true
  }
  const urlRegex = /^(rtsp:\/\/(?:[a-zA-Z0-9\-_]+(?::[a-zA-Z0-9\-_]+)?@)?[a-zA-Z0-9\-_\.]+(?::[0-9]+)?\/(?!live\.sdp)[^\s]*)$|^(https?:\/\/[a-zA-Z0-9\-_\.]+(?::[0-9]+)?\/[^\s]*\.m3u8)$/;
  return urlRegex.test(url);
}

/**
 * 
 * @param {any[]} vehicles The vehicle trajectories
 * @param {any[]} pedestrians The pedestrian trajectories
 * @param {Number} width The camera image width
 * @param {Number} height The camera image height
 * @returns The data required for the heatmap to render (x,y, val, vehicle type)
 */
export function convertToHeatmapData(vehicles, pedestrians, width, height) {
  const pointMap = {};

  const addPoint = (x, y, vehicleType) => {
    const key = `${x},${y},${vehicleType}`;
    if (pointMap[key]) {
      pointMap[key].val += 1;
    } else {
      pointMap[key] = { x, y, val: 1, vehicle_type: vehicleType };
    }
  };

  // Process vehicle trajectories
  vehicles.entries.forEach(entry => {
    entry.exits?.forEach(exit => {
      exit.vehicles?.forEach(vehicle => {
        vehicle.trajectories?.forEach(traj => {
          traj.trajectory.forEach(coord => {
            const x = coord[0] * width;
            const y = coord[1] * height;
            addPoint(x, y, vehicle.vehicle_type);
          });
        });
      });
    });
  });

  // Process pedestrian trajectories
  pedestrians.entries?.forEach(entry => {
    entry.trajectory?.forEach(coord => {
      const x = coord[0] * width;
      const y = coord[1] * height;
      addPoint(x, y, "pedestrian");
    });
  });

  // Convert the map to an array of objects
  return Object.values(pointMap);
}











