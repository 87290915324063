import { useCallback, useEffect, useRef, useState } from "react"
import { Image, Layer, Stage } from "react-konva"
import { useOnResize } from "../../../../../hooks/useOnResize"
import { append, deepClone, updateAt } from "../../../../../utils/data"
import { config } from "./CameraCanvas"
import CameraCanvasLine from "./CameraCanvasLine"
import { v4 as UUIDv4 } from "uuid"
import { IoIosCloseCircleOutline } from "react-icons/io";
import toast from 'react-hot-toast'
import '../../canvas.scss'
const CameraCanvasLines = ({ lines, updateLines, image, prevStep }) => {
  const stageParentRef = useRef(null)
  const stageRef = useRef(null)
  const onResize = useOnResize()

  // We need to have parallel lines in order to avoid update loops
  const [initialLines, setInitialLines] = useState(deepClone(lines))
  const [modifiedLines, setModifiedLines] = useState(deepClone(lines))

  // Use a new variable as it is easier to understand
  const isEditable = updateLines != null

  /**
   * Add a new line.
   *
   * @param {string} type The type of the line: "entry" or "exit".
   */
  const addLine = type => {
    const line = {
      number: initialLines.length + 1,
      type: type,
      new: true,
      points: null,
      id: UUIDv4(),
      carriageway: "",
      lane: ""
    }

    // We must update both lines
    setInitialLines(append(line))
    setModifiedLines(append(line))
  }

  // Handle selected line
  const [selectedId, selectShape] = useState(null)
  const checkDeselect = (e) => {
    if (e.target.attrs.id === "ImageCanvas") {
      selectShape(null)
    }
  }

  const handleDeleteLine = (item, i) => {

    toast.success(`Line ${item.number} has been deleted.
     ${item.number !== initialLines.length ? `Line ${item.number + 1} now becomes Line ${item.number}` : ''}`, {
      duration: 5000
    })
    setModifiedLines(prev => modifiedLines.filter(res => res.id !== item.id).map((obj, i) => {
      return {
        ...obj,
        number: i + 1
      }
    }))
    setInitialLines(prev => initialLines.filter(res => res.id !== item.id).map((obj, i) => {
      return {
        ...obj,
        number: i + 1
      }
    }))
  }

  const handleUpdateLine = (lines) => {
    const res = lines.slice()
    res.map(line => {
      if (line.points === null) {
        const length = 65
        line.points = [
          .5 - length / width,
          .5 - length / height,
          .5 + length / width,
          .5 + length / height
        ]
      }
      return line
    })
    return res
  }


  // Responsive canvas
  const [width, setWidth] = useState(0)
  const height = width / config.aspectRatio
  useEffect(() => {
    if (stageParentRef.current?.offsetWidth) {
      setWidth(stageParentRef.current?.offsetWidth)
    }
  }, [onResize])
  console.log(initialLines)

  return (
    <>
      <div className="mb-5" ref={stageParentRef}>
        <Stage onMouseDown={checkDeselect} onTouchStart={checkDeselect} width={width} height={height} ref={stageRef}>
          <Layer>

            {/* Camera image as a background */}
            <Image image={image} width={width} height={height} id="ImageCanvas" />

            {/* Lines drawn on the image */}
            {initialLines.map((shape, index) => (
              <CameraCanvasLine
                key={shape.id}
                shape={shape}
                width={width}
                height={height}
                isEditable={isEditable}
                updateShape={shape => setModifiedLines(updateAt(index, shape))}
                isSelected={selectedId === shape.id}
                onSelect={() => selectShape(shape.id)} />
            ))}
          </Layer>
        </Stage>

        {/* Add new lines */}
        {isEditable && (
          <div className="mt-2" style={{ display: "flex", justifyContent: 'space-between' }}>
            <div className="row">
              {initialLines.filter(item => item.new).map((item, i) =>
                <span onClick={() => handleDeleteLine(item, i)}
                  className="delBtn" style={{ backgroundColor: item.type === 'entry' ? '#84c342' : '#f64e60' }}
                  key={i}> {item.number} <IoIosCloseCircleOutline className="icon" />
                </span>)}
            </div>

            <div className="mt-2" style={{ display: "flex", justifyContent: "center", gap: ".6em" }}>
              <button className="btn btn-primary" onClick={() => addLine("entry")}>Add entry line</button>
              <button className="btn btn-danger" onClick={() => addLine("exit")}>Add exit line</button>
            </div>
            <div />
          </div>
        )}
      </div>

      {/* Do not if this is a readonly canvas */}
      {isEditable && (
        <div className="form-buttons">
          {prevStep && (<button className="btn btn-default" type="button" onClick={() => prevStep()}>Back</button>)}
          <button className="btn btn-primary" type="submit" onClick={() => updateLines(handleUpdateLine(modifiedLines) )}>Next</button>
        </div>
      )}
    </>
  )
}

export default CameraCanvasLines
