import Error500 from "../../app/pages/Errors/Error500"
import { isEmpty } from "../../utils/functions"
import Loader from "../Loader/Loader"
import { State } from "../../hooks/useLoading"

/**
 * Make sure the supplied state is normalized.
 *
 * @param {*} state The original state of the loading.
 *
 * @return {State} The valid state for the loading wrapper.
 */
export const normalize = (state) => {

  // Handle arrays
  if (Array.isArray(state)) {
    return state.map(normalize)
  }

  // Handle react-query states
  switch (state.status ?? state) {
    case "loading":
      return State.LOADING
    case "success":
      return State.READY
    case "error":
      return State.ERROR
    default:
      return state
  }
}

/**
 * Check if the wrapper should be considered empty.
 *
 * @param {State} state The current state of the loading.
 * @param {any} values The values that are to be shown in the wrapper.
 *
 * @return {boolean} True if the data is finished loading but the values are empty.
 */
export const emptyWrapper = (state, values) =>
  state === State.READY && isEmpty(values)

/**
 * Extract the true state from a single or a list of states.
 *
 * @param {State|State[]} state The original state of the loading.
 *
 * @return {State} The combines state of all states.
 */
export const extractTrueState = (inputState) => {
  console.log(inputState)
  let state = inputState ? inputState : []
  state = normalize(state)

  // True state is the same
  if (typeof state === "string") {
    return state
  }

  // Iterate over all states
  let readyStates = 0
  let emptyStates = 0
  let hasEmpty = false
  for (const singleState of state) {

    // Return error on a single error
    if (singleState === State.ERROR) {
      return State.ERROR
    }

    // Mark as ready (multiple states do not support EMPTY state)
    if (singleState === State.READY || singleState === State.EMPTY) {
      readyStates++
    }
    if(singleState === State.EMPTY){
      emptyStates ++
    }

    // If at least one empty
    hasEmpty ||= singleState === State.EMPTY
  }

  // Return READY only when all the states are ready
  return readyStates < state.length ? State.LOADING : hasEmpty ? State.EMPTY : State.READY
}

/**
 * Allow a state to be empty, but to still show the final result.
 *
 * @param {string} state The original state.
 *
 * @return {string} If original state was EMPTY returns READY, else returns the original state.
 */
export const allowEmpty = (state) =>
  state === State.EMPTY ? State.READY : state

/**
 * Show a loader while downloading, error on any issue or the loaded data.
 *
 * @param {State|State[]} state The state of the loading.
 * @param {LoaderType} type The loader type to use, see Loader.Type for reference.
 * @param {int|null} height The default height of the loading screen.
 * @param {int|null} aspectRatio The default aspect ratio to use while loading.
 * @param {JSX.Element} onLoading The element to show while loading.
 * @param {JSX.Element} onError The element to show on error.
 * @param {JSX.Element|string} onEmpty The element or string to show when the dataset is empty.
 * @param {?function()} isEmpty The function that determines if the data set is empty.
 * @param {JSX.Element} children The children to show when the data is ready.
 */
export const LoadingWrapper = ({
  type,
  state = State.LOADING,
  height,
  aspectRatio,
  children,
  onLoading = <Loader type={type} height={height} aspectRatio={aspectRatio} />,
  onError = <Error500 />,
  onEmpty = "No data to show",
  isEmpty = null,
}) => {
  // Handle refreshing state
  let initial = true
  if (!initial && state === State.LOADING) {
    state = State.REFRESH
  }

  // Handle complex states
  state = extractTrueState(state)

  // Handle empty
  const emptyElement = typeof onEmpty === "string" ? <p className="text-center mt-20">{onEmpty}</p> : onEmpty
  if (isEmpty && isEmpty(state)) {
    return emptyElement
  }

  // What to draw
  const show = () => {
    switch (state) {

      case State.LOADING:
        return onLoading

      case State.READY:
      case State.REFRESH:
        initial = false
        return children

      case State.EMPTY:
        return emptyElement

      default:
      case State.ERROR:
        return onError
    }
  }

  return (
    <div className="loading-wrapper" style={{ minHeight: height ?? "auto", aspectRatio: (aspectRatio ?? "inherit") + "" }}>
      {show()}
    </div>
  )
}
