import { LoadingWrapper } from 'components'
import LargeCard from 'components/Structure/LargeCard'
import { useLoading } from 'hooks/useLoading'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useCameraManagementAPI from 'services/CameraManagementService'
import { humanDate, humanDayTime } from 'utils/time'
import { FiArrowDownLeft, FiArrowUpRight } from "react-icons/fi";
import { Select } from 'components/Events/Fields'
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import styled from '@emotion/styled'

const BillingComp = () => {
    const api = useCameraManagementAPI()
    const [data, loading] = useLoading(() => api.getBillingData())
    const [balType, balTypeLoading] = useLoading(() => api.getBalanceType())

    const [balanceType, setBalanceType] = useState('')
    const [txnData, txnloading] = useLoading(() => api.getTransactionHistory(balanceType), [balanceType])
    console.log({ txnData, data, balanceType })

    useEffect(() => {
        if (balType) {
            setBalanceType(balType?.label)
        }
    }, [balType])

    return (
        <div className="row">
            <div className="col-xl-8 offset-xl-2">
                <LargeCard title='Billing'>
                    <LoadingWrapper state={[loading, txnloading, balTypeLoading]}>
                        {data && balType && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className={`${"col-xl-4 col-md-6"}`}>
                                <Select
                                    name="Balance type"
                                    empty="Filter by"
                                    state={[balanceType, setBalanceType]}
                                    options={balType.map(item => [item.label, item.name])}
                                />
                            </div>
                            <div style={{ justifyContent: 'end', marginBottom: '10px' }} className="process">
                                {Object.keys(data).map((item, i) =>
                                    <Tooltip
                                        sx={{
                                            fontSize: 30
                                        }}
                                        title={
                                            item.includes('available') ?
                                                'Similar to a bank account the available balance is the amount you can use (video files currently being processed are taken in consideration)'
                                                : 'Current is the current balance (video files currently being processed are not taken in consideration).'
                                        }
                                    >
                                        <div className="tile">
                                            <div style={{ textTransform: 'capitalize', fontWeight: '600', fontSize: '24px' }}>
                                                {parseFloat(data[item].hours).toFixed(2)}
                                            </div>
                                            <Link style={{ textTransform: 'capitalize' }} to={'#'}>
                                                {item} Balance
                                            </Link>
                                        </div>
                                    </Tooltip>
                                )}
                            </div>

                        </div>}
                        {txnData ? (
                            <>
                                <Table bordered hover={false} className="v-align">
                                    <thead>
                                        <tr>
                                            <th>Transaction time</th>
                                            <th width="*">Amount (hours)</th>
                                            <th>Batch</th>
                                            <th>Video</th>
                                            <th>Camera</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {txnData.map((item, i) => <tr key={i}>
                                            <td style={{ textTransform: 'capitalize' }}>
                                                {humanDate(item.transaction_time)}{' '}
                                                {humanDayTime(item.transaction_time)}
                                            </td>
                                            <td style={{ display: 'flex', alignItems: 'center' }}>
                                                {item.is_increase ? <FiArrowUpRight style={{ fontSize: 25, color: '#85C340' }} />
                                                    : <FiArrowDownLeft style={{ fontSize: 25, color: 'red' }} />}
                                                {parseFloat(item?.amount?.hours).toFixed(2)}
                                            </td>
                                            <td>{item?.batch_name}</td>
                                            <td>{item?.video_name}</td>
                                            <td>{item?.camera_name}</td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <h1 className="text-center my-10">No data available to show</h1>
                        )}
                    </LoadingWrapper>
                </LargeCard>
            </div >
        </div >
    )
}

export default BillingComp