export const emptyCamera = {
  step: 1,
  uuid: null,
  system: 0,
  name: "",
  url: "",
  description: "",
  country: "",
  locality: "",
  type: '',
  street: "",
  location: "",
  latitude: null,
  longitude: null,
  newImage: "",
  image: null,
  lines: [],
  pairs: []
}

export const devCamera = {
  "step": 4,
  "system": 3,
  "name": "update-lines",
  "uuid": "f29098a5-5e2d-491d-bfbf-df981d62b671",
  "url": "",
  "description": "",
  "newImage": null,
  "image": {
    "url": "http://localhost:8080/storage/camera-image/f29098a5-5e2d-491d-bfbf-df981d62b671",
    "width": 1618,
    "height": 1080
  },
  "country": "Afghanistan",
  "locality": "Kabul",
  "type": "fixed",
  "street": "Airport",
  "location": "",
  "latitude": "35.88727689026155",
  "longitude": "14.447391252898122",
  "lines": [],
  "pairs": []
}
