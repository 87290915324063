import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Table } from "react-bootstrap";
import Gradient from "javascript-color-gradient";
import { ArrowBack } from "@material-ui/icons";
import { buildReportLink } from "app/pages/Reports/Reports";
import { useLoading } from "hooks/useLoading";
import { ExportToCsv, LoadingWrapper } from "components";
import LargeCard from "components/Structure/LargeCard";
import ReportFilters from "components/Reports/ReportFilters";
import { humanNumber, isEmpty } from "utils/functions";
import { readReportParamsFromQuery } from "app/pages/Reports/Reports";
import useAPI from "services/ApiService";
import useStoreDateRange from "hooks/useStoreDateRange";

// Prepare the color gradient
const colorSteps = 100;
const colorGradient = new Gradient();
colorGradient.setGradient("#dcedd4", "#19824a");
colorGradient.setMidpoint(colorSteps);

/**
 * Get a color to use based on the ratio of the current value and the maximum value.
 *
 * @param {number} value The current value.
 * @param {number} max The maximum possible value.
 *
 * @return {string} A CSS valid color to use.
 */
const getCellColor = (value, max) => {
  let step = 0;

  // No background on unknown values
  if (value === "-") {
    return "none";
  }

  // Make sure we have something to measure
  if (value > 0) {
    step = Math.round(colorSteps * Math.min(1, value / max));
  }

  return colorGradient.getColor(Math.max(1, step));
};

/**
 * Get the hour range for the supplied index.
 *
 * @param {int} index The index where 0 represents "00:00 - 01:00" and al the way to 23 for "23:00 - 24:00".
 *
 * @return {string} The hour range as "HH:MM - HH:MM".
 */
const getHourRange = (index) =>
  `${(index + ":00").padStart(5, "0")} - ${(index + 1 + ":00").padStart(
    5,
    "0"
  )}`;

/**
 * Show a week-hour grid report.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const DayOfWeekGridReport = () => {
  const dateRange = useStoreDateRange();
  const api = useAPI();

  // Read Report type
  const reportType = window.location.pathname.split("/")[2];

  // Read report parameters from the URL
  const [params, updateParams] = useState(readReportParamsFromQuery());

  // Load the camera and the data
  const [camera: CameraDTO] = useLoading(() => api.camera(params));
  const [report: [], reportLoadingState] = useLoading(
    () =>
      api.getHeatmap(
        params.systemID,
        params.cameraID,
        dateRange,
        isEmpty(params.lanes) ? params.carriageways : [],
        params.lanes,
        params.vehicles
      ),
    [params]
  );

  // Prepare the variables
  const matrix = [];
  let maxTraffic = -1;
  let shiftedReport = [];

  // Extract the traffic peak and a new matrix that is easier to show in a table
  if (report) {
    // Start from the requested day of the week
    shiftedReport = [...report];
    // Handle the report
    for (const day of shiftedReport) {
      for (const i in day.columns) {
        if (day.columns.hasOwnProperty(i)) {
          // Find the traffic peak in this dataset
          maxTraffic = Math.max(maxTraffic, day.columns[i].value);

          // Set the value for our rotated matrix
          matrix[i] || (matrix[i] = []);
          matrix[i].push(day.columns[i].value);
        }
      }
    }
  }

  // CSV table
  const csv =
    matrix?.map((row, index) => [
      getHourRange(index),
      ...row.map((value) => (value > -1 ? value : ""))
    ]) ?? [];
  csv.unshift(["", ...shiftedReport?.map((day) => day.row_title)]);

  // The header options
  const headerOptions = (
    <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
      <NavLink
        className="mr-5"
        to={buildReportLink(
          reportType,
          {
            systemID: params.systemID,
            cameraID: params.cameraID,
            carriageways: params.carriageways,
            lanes: params.lanes,
            vehicles: params.vehicles,
            range: params.range
          },
          true
        )}
      >
        <ArrowBack /> Back to reports generator
      </NavLink>

      <ExportToCsv data={csv} filename="Day of week grid" />
    </div>
  );
  return (
    <LargeCard title="Day of week grid report" headerOptions={headerOptions}>
      {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

      <LoadingWrapper
        state={reportLoadingState}
        onEmpty={"There is no data to show for the supplied filter"}
      >
        <Table bordered hover>
          <thead>
            <tr>
              <th width="14%">Time</th>
              {// Header: All weekdays
              shiftedReport?.map((day) => (
                <th key={day?.row_title} width="12%" className="text-center">
                  {day.row_title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {matrix?.map((row, index) => (
              <tr key={index}>
                <td>{getHourRange(index)}</td>
                {row?.map((count, index) => {
                  count = count > -1 ? count : "-";
                  return (
                    <td
                      key={index}
                      className="text-center"
                      style={{
                        background: getCellColor(count, maxTraffic * 1.32)
                      }}
                    >
                      {humanNumber(count)}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </LoadingWrapper>
    </LargeCard>
  );
};
