import React, { useEffect, useRef, useState } from 'react';
import { defaultCameraImage, getCameraImageURL } from 'utils/camera';
import { get } from 'utils/data';
import { convertToHeatmapData } from 'utils/functions';
import Heatmap from 'visual-heatmap'; // You might need to adjust this import based on the actual package
import Image from 'img/no-image.png'
import HeatmapComp from './Heatmap';

function HeatmapLayout({ camera, vehicles, pedestrians }) {
    const [imageUrl, setimageUrl] = useState(null)
    // The image to use
    const image = get(camera, ["images", 0], defaultCameraImage);

    useEffect(() => {
        let data = []
        setimageUrl(getCameraImageURL(camera.uuid))

        return () => {

        };
    }, [imageUrl, camera]);

    return (
        <HeatmapComp camera={camera} imageUrl={imageUrl} image={image} />
    );
}

export default HeatmapLayout;