
// Examples of trajectories with custom palette
// https://azure.greenroadsmalta.com/cameras/trajectories/greenroads/greenroads-streams1-stream1?palette=bfef45,ffe119,e6194b,fabed4,5e8bc1,ececec,ff8f41
// https://azure.greenroadsmalta.com/cameras/trajectories/greenroads/greenroads-streams1-stream1?palette=0000ff,b1c946,ff9900,00ff00,0000ff,00ffff,e1e1e1

// List of predefined colors
const colorIndex = 0;
const colors = [
  [
    "#bfef45", "#ffe119", "#e6194b", "#fabed4",
    "#5e8bc1", "#ececec", "#ff8f41", "#ff8f41"
  ],
  [
    "#e60049", "#0bb4ff", "#50e991", "#e6d800",
    "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff"
  ],
  [
    "#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe",
    "#ffb55a", "#ffee65", "#beb9db", "#fdcce5"
  ]
];

// Read colors from the URL
const fromURL = new URLSearchParams(window.location.search).get("palette")?.split(",");

// Either from the URL or from the list of predefined
const palette = fromURL
  ? fromURL.map(color => "#" + color)
  : colors[colorIndex];

// Pick colors from the palette
let counter = 0;
const color = () => palette[counter++ % palette.length];

/**
 * The list of all supported vehicle types.
 *
 * @TODO Read from the API and store in local cache.
 *
 * @return VehicleTypeDTO[]
 */
const VehicleTypes = [
  {
    type: "car",
    name: "Car",
    plural: "Cars",
    color: color()
  },
  {
    type: "light-goods-vehicle",
    name: "Light goods vehicle",
    plural: "Light goods vehicles",
    color: color()
  },
  {
    type: "heavy-goods-vehicle",
    name: "Heavy goods vehicle",
    plural: "Heavy goods vehicles",
    color: color()
  },
  {
    type: "bus",
    name: "Bus",
    plural: "Buses",
    color: color()
  },
  {
    type: "motorcycle",
    name: "Motorcycle",
    plural: "Motorcycles",
    color: color()
  },
  {
    type: "bicycle",
    name: "Bicycle",
    plural: "Bicycles",
    color: color()
  },
  {
    type: "pedestrian",
    name: "Pedestrian",
    plural: "Pedestrians",
    color: color()
  }
];

/**
 * Get available vehicle types.
 *
 * @param {boolean} withPedestrians True to get with pedestrians, false otherwise.
 *
 * @return {VehicleTypeDefinitionDTO[]} The list of supported vehicle types.
 */
export const getVehicleTypes = (withPedestrians: boolean = false): VehicleTypeDefinitionDTO[] =>
  !withPedestrians
    ? VehicleTypes.filter(type => type.type !== "pedestrian")
    : VehicleTypes;

/**
 * Sort the data by the supported vehicle types.
 *
 * @param {{}} data The data to sort.
 * @param {{}} empty The value to set if the data does not contain info for a vehicle type.
 * @param {?VehicleTypes[]} vehicleTypes The list of vehicle types to sort, or null to use all of them.
 *
 * @return {[]} The data sorted by the supported vehicle types.
 */
export const sortByVehicleType = (data, empty = {}, vehicleTypes = null) =>
  (vehicleTypes ?? getVehicleTypes()).map(type => ({
    vehicle_type: type.type,
    vehicle_name: type.name,
    vehicle_plural: type.plural,
    ...(data.find(item => item.vehicle_type === type.type) ?? empty)
  }));

/**
 * Get the color for the vehicle type.
 *
 * @param {string} vehicleType The code of the vehicle type.
 *
 * @return {string} The color to use for this type.
 */
export const getVehicleTypeColor = (vehicleType: string): string =>
  (VehicleTypes.find(type => type.type === vehicleType) ?? { color: "#95cc98" })["color"];
