/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers"
import {
  menuItems, userManagementRoute, tenantsRoute, cameraManagementRoute,
  cameraFilesRoute, auditRoute, eventsManagementRoute, EventsDashboard, multiCameraReportRoute, settingsRoute, billingRoute, Multiscene, LiveCameraRoute, documentationRoute
} from "../../header/header-menu/menu-items"
import { useMe } from "hooks/useMe"
import { useGetTenants } from "app/pages/Tenants/queries"

export function AsideMenuList({ layoutProps }) {
  const asideMenu = [...menuItems]

  const { isAdmin, isGreenRoadsAdmin } = useMe()
  const { data: tenants } = useGetTenants()

  if (!asideMenu.some((item) => item.name === multiCameraReportRoute.name)) {
    asideMenu.push(multiCameraReportRoute)
  }
  if (!asideMenu.some((item) => item.name === Multiscene.name)) {
    asideMenu.push(Multiscene)
  }
  if (!asideMenu.some((item) => item.name === eventsManagementRoute.name)) {
    asideMenu.push(eventsManagementRoute)
  }
  
  if (isAdmin && !asideMenu.some((item) => item.name === EventsDashboard.name)) {
    asideMenu.push(EventsDashboard)
  }

  // Camera management
  if (isAdmin && !asideMenu.some((item) => item.name === cameraManagementRoute.name)) {
    asideMenu.push(cameraManagementRoute)
  }

  // Camera Files
  if (isAdmin && !asideMenu.some((item) => item.name === cameraFilesRoute.name)) {
    asideMenu.push(cameraFilesRoute)
  }


  // // Event log
  if (isAdmin && !asideMenu.some((item) => item.name === auditRoute.name)) {
    asideMenu.push(auditRoute)
  }
  if (isAdmin && !asideMenu.some((item) => item.name === billingRoute.name) &&
    tenants && tenants.some((item) => item.system_id === 'greenroads')) {
    asideMenu.push(billingRoute)
  }
  if (isAdmin && !asideMenu.some((item) => item.name === userManagementRoute.name)) {
    asideMenu.push(userManagementRoute)
  }
  if (!asideMenu.some((item) => item.name === documentationRoute.name)) {
    asideMenu.push(documentationRoute)
  }
  if (isAdmin && !asideMenu.some((item) => item.name === settingsRoute.name)) {
    asideMenu.push(settingsRoute)
  }

  if (
    isAdmin && isGreenRoadsAdmin &&
    !asideMenu.some((item) => item.name === tenantsRoute.name)
  ) {
    asideMenu.push(tenantsRoute)
  }

  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-highlighted` : ""
  }

  // useEffect(() => {
  //   console.log(tenants)
  //   if (
  //     tenants?.find((tn) => tn?.system_id === "greenroads")?.system_id === "greenroads" &&
  //     isAdmin &&
  //     !asideMenu.some((item) => item.name === tenantsRoute.name)
  //   ) {
  //     asideMenu.push(tenantsRoute)
  //   }

  //   return () => {

  //   }
  // }, [tenants, isAdmin])


  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {asideMenu.map((item, index) => (
          <li className={`menu-item ${getMenuItemActive(item.to, false)}`} key={index}>
            <NavLink className="menu-link" to={item.to}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl(item.svgIcon)} title={item.name} />
              </span>
              <span className="menu-text">{item.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
