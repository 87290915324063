import { useFormik } from "formik"
import { Form } from "react-bootstrap"
import { allowEmpty } from "../../../../components/LoadingWrapper/LoadingWrapper"
import LargeCard from "../../../../components/Structure/LargeCard"
import { ExtendableSelectInput, SelectInput, StringInput, TextInput } from "../../../../components/Reports/Fields"
import countries from "../../../../config/countries"
import { cameraManagementStep2, formikDefault } from "../../../../utils/forms-schema"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { LoadingWrapper } from "../../../../components"
import { useLoading } from "../../../../hooks/useLoading"
import { stepTitle } from "../form"

/**
 * Set the basic information about the camera.
 *
 * @param camera
 * @param title
 * @param setValues
 * @param prevStep
 * @return {JSX.Element}
 * @constructor
 */
const Step2 = ({ camera, title, setValues, prevStep }) => {
  const api = useCameraManagementAPI()

  // Define form
  const forSelect = (data, key = "name") => (data ?? []).map(item => ({ value: item[key], label: item.name }))
  const formik = useFormik({
    ...formikDefault,
    initialValues: camera,
    validationSchema: cameraManagementStep2,
    onSubmit: setValues
  })

  // Get the list of locations and the available systems for the user
  const [systems: CMSystemResponse[], systemsLoadingState] = useLoading(() => api.systems())
  const [existingCountries: CMCountryResponse[], countriesLoadingState] = useLoading(() => api.countries())
  const [localities: CMLocalityResponse[], localitiesLoadingState] = useLoading(() => api.localities())
  const [streets: CMStreetResponse[], streetsLoadingState] = useLoading(() => api.streets())

  // Get the id of the selected locations
  const countryId = (existingCountries ?? []).find(country => country.name === formik.values.country)?.id
  const localityId = (localities ?? []).find(locality => locality.name === formik.values.locality)?.id

  const cameraType = [
    {
      name: 'DRONE', id: 'drone'
    },
    {
      name: 'FIXED CAMERA', id: 'fixed'
    }
  ]
  return (
    <LargeCard title={stepTitle(title, camera, "Basic info")}>
      <LoadingWrapper state={[systemsLoadingState, allowEmpty(countriesLoadingState), allowEmpty(localitiesLoadingState), allowEmpty(streetsLoadingState)]}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              {(systems ?? []).length > 1 && <SelectInput label="System" name="system" options={forSelect(systems, "id")} formik={formik} />}
              <StringInput label="Camera name" name="name" formik={formik} />
              <SelectInput label="Camera type" name="type" options={forSelect(cameraType, "id")} formik={formik} />
              <TextInput label="Description" name="description" rows={4} formik={formik} />
            </div>

            <div className="col-md-6">
              <ExtendableSelectInput label="Country" name="country" options={forSelect(countries)} formik={formik} />
              <ExtendableSelectInput label="Locality" name="locality" options={forSelect((localities ?? []).filter(locality => locality.country_id === countryId))} formik={formik} />
              <ExtendableSelectInput label="Street" name="street" options={forSelect((streets ?? []).filter(street => street.locality_id === localityId))} formik={formik} />
            </div>
          </div>

          <div className="form-buttons">
            <button className="btn btn-default" type="button" onClick={() => prevStep()}>Back</button>
            <button className="btn btn-primary" type="submit">Next</button>
          </div>
        </Form>
      </LoadingWrapper>
    </LargeCard>
  )
}

export default Step2
