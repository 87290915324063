import { useState } from "react";
import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { buildReportLink } from "../Reports";
import { humanDate, humanDayTime } from "utils/time";
import { useLoading } from "hooks/useLoading";
import { ExportToCsv, LoadingWrapper } from "components";
import { getPcuValueForReport, humanNumber, humanPercent, isNotEmpty } from "utils/functions";
import LargeCard from "components/Structure/LargeCard";
import ReportFilters from "components/Reports/ReportFilters";
import { readReportParamsFromQuery } from "../Reports";
import { getVehicleTypes } from "config/VehicleTypes";
import { ArrowBack } from "@material-ui/icons";
import useAPI from "services/ApiService";
import useStoreDateRange from "hooks/useStoreDateRange";
import { Select } from "components/Reports/Fields";
import useCameraManagementAPI from "services/CameraManagementService";
import PaginationBarReport from "components/Pagination/PaginationBarReport";

export const VehicleTypeHourlyBreakdownReportPage = () => {
  const dateRange = useStoreDateRange();
  const api = useAPI();
  const cameraApi = useCameraManagementAPI()

  // Read Report type
  const reportType = window.location.pathname.split("/")[2];

  // Read report parameters from the URL
  const [params, updateParams] = useState(readReportParamsFromQuery());
  const [granularity, setGranularity] = useState(60)
  // Load the data
  const [camera: CameraDTO] = useLoading(() => api.camera(params));
  const [report: VehicleTypeGranularJSON[], reportLoadingState] = useLoading(() =>
    api.getVehicleTypeGranular(params.systemID, params.cameraID, dateRange, params.vehicles, params.carriageways, params.lanes, granularity),
    [params, granularity]
  );
  const [pcu, pcuLoadingState] = useLoading(() =>
    cameraApi.getPcuReport(dateRange, params.vehicles),
    [params]
  )

  // Vehicle types
  const vehicles = isNotEmpty(params.vehicles)
    ? params.vehicles.map((type) => getVehicleTypes().find((vehicle) => vehicle.type === type))
    : getVehicleTypes();

  // Build csv data
  let csv = [
    ["From", "To", "Total", 'pcu', ...vehicles.map((vehicle) => vehicle.name)]
  ];
  for (const row of report ?? []) {
    let total = 0;

    // Show the from and to
    const line = [row.start, row.end, 0, 0];

    // Add the values for the vehicle types
    for (const vehicle of vehicles) {
      const count =
        row.data.find((item) => item.vehicle_type === vehicle.type)?.count ?? 0;
      line.push(count);
      total += count;
    }
    line[2] = total;
    line[3] = getPcuValueForReport(row.data, pcu)

    csv.push(line);
  }

  // The header options
  const headerOptions = (
    <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
      <NavLink
        className="mr-5"
        to={buildReportLink(reportType, {
          systemID: params.systemID,
          cameraID: params.cameraID,
          carriageways: params.carriageways,
          lanes: params.lanes,
          vehicles: params.vehicles,
          range: params.range
        }, true)}
      >
        <ArrowBack /> Back to reports generator
      </NavLink>
      <ExportToCsv data={csv} filename="Vehicle type breakdown" />
    </div>
  );

  // The width of columns representing vehicle type and the total count
  const colWidth = (100 - 40) / (vehicles.length + 1) + "%";

  const timeBreakdown = [{
    name: 'Hourly',
    granularity: 60
  },
  {
    name: '15 minutes',
    granularity: 15
  },
  ]
  const TimeFilterComp = () => {
    return (
      <div className={`${"col-xl-4 col-md-6"}`}>
        <Select
          name="Report"
          empty="Time filter"
          state={[granularity, setGranularity]}
          options={timeBreakdown.map(time => [time.granularity, time.name])}
        />
      </div>
    )
  }

  return (
    <LargeCard title="Vehicle type breakdown report" headerOptions={headerOptions}>

      {camera && <ReportFilters report={reportType}
        camera={camera} updateParams={updateParams}
        noVehicleFilter={true} filterComp={TimeFilterComp} />}

      <LoadingWrapper state={[reportLoadingState, pcuLoadingState]} onEmpty={"There is no data to show for the supplied filter"}>

        <Table bordered hover>
          <thead>
            <tr>
              <th width="15%">Date</th>
              <th width="15%">Time</th>

              <th width={colWidth} className="text-right">
                Total
              </th>
              <th>PCU</th>
              {// List all of the vehicle types as the table header
                vehicles.map((vehicle) => (
                  <th key={vehicle.type} width={colWidth} className="text-right">
                    {vehicle.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {pcu && report?.map((row) => {
              // Extract the data for all of the vehicle types
              let cells = [];
              for (const vehicle of vehicles) {
                cells.push({
                  vehicle,
                  count:
                    row.data.find((cell) => cell.vehicle_type === vehicle.type)
                      ?.count ?? 0,
                  avg_speed:
                    row.data.find((cell) => cell.vehicle_type === vehicle.type)
                      ?.avg_speed ?? 0
                });
              }
              // Calculate the total and the percentages for each cell
              const total = cells.reduce((sum, cell) => sum + cell.count, 0);
              cells = cells.map((cell) => ({
                ...cell,
                percent: (100 * cell.count) / total
              }));

              return (
                <tr key={row.start}>
                  <td>{humanDate(row.start)}</td>
                  <td>{`${humanDayTime(row.start)} - ${humanDayTime(
                    row.end
                  ).replace("00:00", "24:00")}`}</td>
                  <td className="text-right">{humanNumber(total)}</td>
                  {/* <td className="text-right">{humanNumber(total)}</td> */}
                  <td className="text-right">{getPcuValueForReport(row?.data, pcu)}</td>
                  {cells.map((cell, index) => (
                    <td
                      key={index}
                      className="text-right"
                      title={humanPercent(cell.percent)}
                    >
                      {humanNumber(cell.count)}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* {report && <PaginationBarReport
                    type={reportType}
                    params={params}
                    arr={report} />} */}
      </LoadingWrapper>
    </LargeCard>
  );
};
