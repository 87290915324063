import React, { useEffect, useState } from "react";
import { CameraOperationState } from "../../../components/CameraOperationState/CameraOperationState"
import { Dashboard } from "./Details/SubPages/Dashboard";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../../hooks/useLoading";
import { setPrevCamera, updateCameraAction } from "../../../redux/cameraSlice";
import { useDispatch } from "react-redux";
import useAPI from "../../../services/ApiService";
import { useOnResize } from "../../../hooks/useOnResize";
import { defaultCameraImage } from "../../../utils/camera";
import { Loader, LoadingWrapper } from "../../../components";
import { BarChart, DeviceHub, Videocam, CenterFocusWeak } from "@material-ui/icons";
import { get } from "../../../utils/data";
import Trajectories from "./Details/SubPages/Trajectories";
import { Stats } from "./Details/SubPages/Stats";
import HeaderButtons from "../../../components/HeaderButtons/HeaderButtons";
import { MultiSelect } from "components/Events/Fields";
import TrafficDiagramWrapper from "./Details/SubPages/Dashboard/TrafficDiagramWrapper";


export const reportTypeMenu = [{
  value: 'lane',
  label: 'lane metrics',
  id: 'lane'
}, {
  value: 'carriageway',
  label: 'carriageway metrics',
  id: 'carriageway'
},]
export const CameraDetails = () => {
  const onResize = useOnResize();
  const dispatch = useDispatch();
  const api = useAPI();
  const { cameraID } = useParams()
  // Handle date changes
  const { location } = useHistory()
  const [ready, setReady] = useState(false)
  const [reportType, setReportType] = useState([])
  // Load the system and camera id
  const params = useParams();

  // Load the camera and update the redux state
  const [camera: CameraDTO, cameraLoadingState] = useLoading(() => api.camera(params), [onResize, cameraID]);

  useEffect(() => {
    // Ensure we have a fallback image
    const image = get(camera, ["images", 0], defaultCameraImage);

    dispatch(
      updateCameraAction({
        image: image?.url,
        width: image?.width,
        height: image?.height,
        uuid: camera?.camera_uuid,
        location: camera?.location ?? "",
        group: camera?.group ?? "",
        name: camera?.name ?? "",
        systemID: camera?.sys_id ?? ""
      })
    );
  }, [dispatch, camera
  ]);

  useEffect(() => {
    if (location.pathname.includes('cameras/trajectories')) {
      setReady(true)
    }

  }, [location.pathname])

  // set the application previous camera
  useEffect(() => {
    dispatch(setPrevCamera(camera))
  }, [camera, dispatch])


  // Define buttons for the header
  const headerButtons = [
  
    {
      icon: <DeviceHub />,
      text: "Trajectories",
      path: `/cameras/trajectories/${camera?.sys_id}/${camera?.camera_id}`
    },
    {
      icon: <BarChart />,
      text: "Stats",
      path: `/cameras/stats/${camera?.sys_id}/${camera?.camera_id}`
    },
    {
      icon: <Videocam />,
      text: "Overview",
      path: `/cameras/dashboard/${camera?.sys_id}/${camera?.camera_id}`
    },
    {
      icon: <CenterFocusWeak />,
      text: "OD Matrix",
      path: `/cameras/footage/${camera?.sys_id}/${camera?.camera_id}`
    },
  ];

  return (
    <>
      <LoadingWrapper state={cameraLoadingState}>
        {camera && <div className="CameraPage">

          <div className="camera-header clearfix mb-5 row">

            <div className="col-xl-7">
              <h1 style={{ display: "inline-block" }}>
                <CameraOperationState camera={camera} position="float-left" />
                {camera?.name} <br />
                <small style={{ fontSize: "14px" }}>{camera?.location}</small>
              </h1>
            </div>

            <div className="col-xl-5">
              <HeaderButtons buttons={headerButtons} />
            </div>

          </div>

          {/* The sub-page to show */}
          {(() => {

            switch (get(params, "subpage")) {
              default:
              case "overview":
                return <Dashboard camera={camera} />;
              case "stats":
                return <Stats camera={camera} />;
              case "trajectories":
                return ready ? <Trajectories camera={camera} /> : <Loader />;
              case "footage":
                return <TrafficDiagramWrapper camera={camera} />
            }
          })()}

        </div>}
      </LoadingWrapper>
    </>
  );
};
