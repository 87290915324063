import { Fragment, useState } from "react"
import { Table } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { buildReportLink } from "../Reports"
import { useLoading } from "hooks/useLoading"
import { ExportToCsv, LoadingWrapper } from "components"
import { humanNumber, humanSpeed, humanTotal } from "utils/functions"
import LargeCard from "components/Structure/LargeCard"
import ReportFilters from "components/Reports/ReportFilters"
import { csvAvgSpeed } from "utils/csv"
import { readReportParamsFromQuery } from "../Reports"
import { groupLanesByCarriageway } from "utils/carriageways"
import { ArrowBack } from "@material-ui/icons"
import useAPI from "services/ApiService"
import useStoreDateRange from "hooks/useStoreDateRange"

export const LanesSummaryReportPage = () => {
  const dateRange = useStoreDateRange()
  const api = useAPI()

  // Read Report type
  const reportType = window.location.pathname.split("/")[2]

  // Read report parameters from the URL
  const [params, updateParams] = useState(readReportParamsFromQuery())

  // Load the data
  const [camera: CameraDTO] = useLoading(() => api.camera(params))
  const [report: LanesSummaryReportDTO, reportLoadingState] = useLoading(() =>
      api.getLanesSummary(params.systemID, params.cameraID, dateRange, params.carriageways, params.lanes, params.vehicles),
    [params],
    ["data"]
  )

  // CSV table
  const csv = report?.data.map((row) => [
    row.carriageway,
    row.lane,
    row.entry_count,
    row.exit_count,
    csvAvgSpeed(row.avg_speed)
  ])
  csv?.unshift(["Carriageway", "Lane", "Entries", "Exits", "Avg speed [km/h]"])

  // Group lanes by carriageway
  const dataByCarriageway = report ? groupLanesByCarriageway(report.data) : []

  // The header options
  const headerOptions = (
    <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
      <NavLink
        className="mr-5"
        to={buildReportLink(reportType, {
          systemID: params.systemID,
          cameraID: params.cameraID,
          carriageways: params.carriageways,
          lanes: params.lanes,
          vehicles: params.vehicles,
          range: params.range
        }, true)}
      >
        <ArrowBack /> Back to reports generator
      </NavLink>
      <ExportToCsv data={csv} filename="Lanes summary" />
    </div>
  )

  return (
    <LargeCard title="Lane summary report" headerOptions={headerOptions}>

      {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

      <LoadingWrapper state={reportLoadingState} onEmpty={"There is no data to show for the supplied filter"}>

        <Table bordered hover>
          <thead>
            <tr>
              <th width="*">Lane</th>
              <th width="25%" className="text-right">Entries</th>
              <th width="25%" className="text-right">Exits</th>
              <th width="15%" className="text-right">Avg speed</th>
            </tr>
          </thead>
          <tbody>
            {report &&
              Object.keys(dataByCarriageway).map((carriageway) => (
                <Fragment key={carriageway}>
                  <tr className="table-header">
                    <td colSpan="8">{carriageway}</td>
                  </tr>
                  {dataByCarriageway[carriageway].map((row) => (
                    <tr key={`${row.entry_uuid}-${row.exit_uuid}`}>
                      <td>{row.lane}</td>
                      <td className="text-right">{humanNumber(row[`entry_count`])}</td>
                      <td className="text-right">{humanNumber(row[`exit_count`])}</td>
                      <td className="text-right">{humanSpeed(row.avg_speed)}</td>
                    </tr>
                  ))}
                </Fragment>
              ))}

            {/* Total */}
            <tr>
              <td />
              <td className="text-right">
                {humanTotal(humanNumber(report?.data.reduce((acc, row) => acc + row.entry_count, 0)), " entries")}
              </td>
              <td className="text-right">
                {humanTotal(humanNumber(report?.data.reduce((acc, row) => acc + row.exit_count, 0)), " exits")}
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
      </LoadingWrapper>
    </LargeCard>
  )
}
